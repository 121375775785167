import Dashboard from 'Components/Dashboard'
import AdminDashboard from 'Components/AdminDashboard'
import AdminDashboardV2 from 'Components/AdminDashboardV2'
import Advisor from 'Components/Advisor'
import AdvisorAddEdit from 'Components/Advisor/AdvisorAddEdit'
import BasicSettingTab from 'Components/AdminSetting/BasicSettingTab'
import FormSyncSetting from 'Components/FormSyncSetting'
import Profile from 'Components/Profile'
import EmailTemplates from 'Components/EmailTemplates'
import EmailTemplatesEdit from 'Components/EmailTemplates/EmailTemplatesEdit'
import EmergencyBanner from 'Components/EmergencyBanner'
import EmergencyBannerAddEdit from 'Components/EmergencyBanner/EmergencyBannerAddEdit'
import Student from 'Components/Student/index'
import VisaPortalStudentIndex from 'Components/Student/VisaPortal/index'
import FieldFilter from 'Components/FieldFilter'
import { RouteItem } from './type'
import { ManagersRoles, Superadmin, AdvisorsRoles } from 'CommonJS/roleHelper'
import InsurancePortalAddEdit from 'Components/DashboardIcon/InsurancePortal/InsurancePortalAddEdit'
import InsurancePortal from 'Components/DashboardIcon/InsurancePortal'
import PreDepartureGuideAddEdit from 'Components/DashboardIcon/PreDepartureGuide/PreDepartureGuideAddEdit'
import PreDepartureGuide from 'Components/DashboardIcon/PreDepartureGuide'
import PreDepartureCourseAddEdit from 'Components/DashboardIcon/PreDepartureCourse/PreDepartureCourseAddEdit'
import PreDepartureCourse from 'Components/DashboardIcon/PreDepartureCourse'
import SmartphoneApp from 'Components/DashboardIcon/SmartphoneApp'
import SmartphoneAppAddEdit from 'Components/DashboardIcon/SmartphoneApp/SmartphoneAppAddEdit'
import CourseSchedule from 'Components/DashboardIcon/CourseSchedule'
import CourseScheduleAddEdit from 'Components/DashboardIcon/CourseSchedule/CourseScheduleAddEdit'
import StudentView from 'Components/StudentView'
import MailConfig from 'Components/AdminSetting/MailConfig'
import GlobalView from 'Components/StudentView/GlobalView'
import { PermissionEnum } from 'CommonJS/PermissionEnum'
import RolePermissions from 'Components/AdminSetting/RolePermissions'
import VisaDashboard from 'Components/VisaDashboard'
import VisaStudentDashboard from 'Components/VisaStudentDashboard'
import StudentInfo from 'Components/StudentInfo'
import ActivitiesAttended from 'Components/ActivitiesAttended'
import StudentInfoTag from 'Components/StudentInfoTag'
import { BulkActionsEnum } from 'Components/Student/BulkActionsEnum'
import StudentDashboardBy from 'Components/StudentDashboardBy'

export const routes: RouteItem[] = [
  {
    path: '/',
    layout: 'default',
    component: <Dashboard />,
    allowedRoles: ['student']
  },
  {
    path: 'admin/student-dashboard/:id',
    layout: 'default',
    component: <Dashboard />,
    allowedRoles: ManagersRoles
  },
  {
    path: 'admin/student-info/:id',
    layout: 'admin',
    component: <StudentInfo />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/admin/student',
    layout: 'admin',
    component: <Student />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/admin/visa-student',
    layout: 'admin',
    component: <VisaPortalStudentIndex />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/dashboard',
    layout: 'admin',
    component: <AdminDashboardV2 />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/dashboard-v1',
    layout: 'admin',
    component: <AdminDashboard />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/dashboard-visa',
    layout: 'admin',
    component: <VisaStudentDashboard />,
    allowedRoles: ManagersRoles
  },
  {
    path: `/dashboard-${BulkActionsEnum.MoveToCurrent}`,
    layout: 'admin',
    component: <StudentDashboardBy viewBy={BulkActionsEnum.MoveToCurrent} />,
    allowedRoles: ManagersRoles
  },
  {
    path: `/dashboard-${BulkActionsEnum.MoveToAlumni}`,
    layout: 'admin',
    component: <StudentDashboardBy viewBy={BulkActionsEnum.MoveToAlumni} />,
    allowedRoles: ManagersRoles
  },
  {
    path: `/dashboard-${BulkActionsEnum.WithdrawApplication}`,
    layout: 'admin',
    component: <StudentDashboardBy viewBy={BulkActionsEnum.WithdrawApplication} />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/admin/setting/*',
    layout: 'admin',
    allowedRoles: ['superadmin'],
    children: [
      { path: '/mail', component: <MailConfig /> },
      { path: '/form', component: <FormSyncSetting /> }
    ]
  },
  {
    path: '/admin/setting/*',
    layout: 'admin',
    allowedRoles: ['superadmin', 'advisor'],
    children: [
      { path: '/basic', component: <BasicSettingTab /> },
      { path: '/template', component: <EmailTemplates /> }
    ]
  },
  {
    path: '/admin/users',
    layout: 'admin',
    component: <Advisor />,
    allowedRoles: ['superadmin']
  },
  {
    path: '/admin/users/add/',
    layout: 'admin',
    component: <AdvisorAddEdit />,
    allowedRoles: ['superadmin']
  },
  {
    path: '/admin/users/edit',
    layout: 'admin',
    component: <AdvisorAddEdit />,
    allowedRoles: ['superadmin']
  },
  {
    path: '/admin/users/view',
    layout: 'admin',
    component: <AdvisorAddEdit />,
    allowedRoles: ['superadmin']
  },
  {
    path: '/admin/setting/template/edit',
    layout: 'admin',
    component: <EmailTemplatesEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/template/view',
    layout: 'admin',
    component: <EmailTemplatesEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/profile',
    layout: 'admin',
    component: <Profile />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/admin/setting/emergency-banner/',
    layout: 'admin',
    component: <EmergencyBanner />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/emergency-banner/add',
    layout: 'admin',
    component: <EmergencyBannerAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/emergency-banner/edit/:id',
    layout: 'admin',
    component: <EmergencyBannerAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/field-filter/',
    layout: 'admin',
    component: <FieldFilter />,
    allowedRoles: ['superadmin']
  },
  {
    path: '/admin/setting/insurance-portal/',
    layout: 'admin',
    component: <InsurancePortal />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/insurance-portal/add',
    layout: 'admin',
    component: <InsurancePortalAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/insurance-portal/edit/:id',
    layout: 'admin',
    component: <InsurancePortalAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-guide/',
    layout: 'admin',
    component: <PreDepartureGuide />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-guide/add',
    layout: 'admin',
    component: <PreDepartureGuideAddEdit />,
    allowedRoles: ['superadmin', 'advisor', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-guide/edit/:id',
    layout: 'admin',
    component: <PreDepartureGuideAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-course/',
    layout: 'admin',
    component: <PreDepartureCourse />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-course/add',
    layout: 'admin',
    component: <PreDepartureCourseAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/pre-departure-course/edit/:id',
    layout: 'admin',
    component: <PreDepartureCourseAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/smartphone-app/',
    layout: 'admin',
    component: <SmartphoneApp />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/smartphone-app/add',
    layout: 'admin',
    component: <SmartphoneAppAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/smartphone-app/edit/:id',
    layout: 'admin',
    component: <SmartphoneAppAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/course-schedule/',
    layout: 'admin',
    component: <CourseSchedule />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/course-schedule/add',
    layout: 'admin',
    component: <CourseScheduleAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/course-schedule/edit/:id',
    layout: 'admin',
    component: <CourseScheduleAddEdit />,
    allowedRoles: ['superadmin', 'advisor']
  },
  {
    path: '/admin/setting/activities-attended',
    layout: 'admin',
    component: <ActivitiesAttended />,
    allowedRoles: [Superadmin]
  },
  {
    path: '/admin/setting/tag',
    layout: 'admin',
    component: <StudentInfoTag />,
    allowedRoles: [Superadmin]
  },
  {
    path: '/admin/setting/roles-permissions',
    layout: 'admin',
    allowedRoles: [Superadmin],
    component: <RolePermissions />
  },
  {
    path: '/admin/student/view/',
    layout: 'admin',
    component: <StudentView />,
    allowedRoles: [Superadmin, ...AdvisorsRoles],
    permissions: [PermissionEnum.STUDENT_LIST_MANAGE_VIEW]
  },
  {
    path: '/admin/student/global-view/*',
    layout: 'admin',
    allowedRoles: [Superadmin],
    component: <GlobalView />
  },
  {
    path: '/admin/student/:viewBy',
    layout: 'admin',
    component: <Student />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/admin/visa-dashboard/:id',
    layout: 'default',
    component: <VisaDashboard />,
    allowedRoles: ManagersRoles
  },
  {
    path: '/visa-dashboard',
    layout: 'default',
    component: <VisaDashboard />,
    allowedRoles: ['student']
  }
]
