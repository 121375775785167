import http from 'CommonJS/http'
import { AxiosResponse } from 'axios'

interface UpdateStudentInfoDataType {
  studentId?: number
  data?: Record<string, unknown>
  params?: Record<string, unknown>
  onError?: (message: string) => void
  onSuccess?: (message: string) => void
}

/**
 * Create/Update students meta key value.
 */
export async function updateStudentInfo({ studentId = 1, params, data, onError, onSuccess }: UpdateStudentInfoDataType) {
  return http
    .fetch({
      method: 'post',
      path: `students/${studentId}/studentInfo`,
      data: data,
      options: {
        params: {
          ...(params ?? {})
        }
      }
    })
    .catch(() => {
      onError && onError('Internal Server Error.')
    })
    .then((response: void | AxiosResponse<{ success: boolean; message: string }>) => {
      if (response && response.status === 200) {
        onSuccess && onSuccess(response.data.message)
      }
    })
}
