import Checkbox from '@mui/material/Checkbox'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { studentContext } from './context'
import { ActionType, ChoiceFieldDataType, Fields, SaveChangeFilterCallBackParam, SaveChangeFilterResponse } from './types'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import Loading from 'Components/Loading'
import { Autocomplete, FormControlLabel, TextField } from '@mui/material'
import { DndProvider, XYCoord, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { isSuperAdmin } from 'CommonJS/roleHelper'
import { UserContext } from 'Context/UserContext'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { AxiosResponse } from 'axios'

interface Props {
  setOpenColumnFilter: React.Dispatch<React.SetStateAction<boolean>>
  isToggleFilter?: boolean
  show?: boolean
  getFields?: () => Promise<void>
}

export default function ColumnFilter({ setOpenColumnFilter, isToggleFilter, show, getFields }: Props) {
  const { state, dispatch } = useContext(studentContext)
  const { fields, column, filters } = state
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [disabledFields, setDisabledFields] = useState(fields)
  const [localFields, setLocalFields] = useState(fields)
  const [loading, setLoading] = useState(false)
  const { data } = useContext(UserContext)
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [actionLabel, setActionLabel] = useState<string>('')
  const columnListVirtuoso = useRef<VirtuosoHandle>(null)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [localFieldsFilteredData, setLocalFieldsFilteredData] = useState(fields)
  const [fieldsFilteredData, setFieldsFilteredData] = useState(
    fields.filter((i) => i.fieldName !== 'profile_image' && i.fieldName !== 'action' && i.fieldName !== 'assign_advisor')
  )

  useEffect(() => {
    const sortFields = async () => {
      const filterFields = await state.fields.filter((item) => item.isDisabled === false)
      const fields = await filterFields?.sort(function (a, b) {
        return a.filterColumnOrder - b.filterColumnOrder
      })

      setDisabledFields(disabledFields)
      await setLocalFields(fields)
      await setLocalFieldsFilteredData(fields)
    }
    sortFields()
  }, [state.fields])

  const handleCheckboxChange = (id: string) => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id))
    } else {
      setCheckedIds([...checkedIds, id])
    }
  }

  const saveChange = async (callback: SaveChangeFilterCallBackParam) => {
    setLoading(true)
    return http
      .fetch({ method: 'post', path: '/setting/fields', data: { fields: checkedIds } })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
        setLoading(false)
      })
      .then((response: void | AxiosResponse<SaveChangeFilterResponse>) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
          callback && callback(response?.data.fieldData)
        }
      })
  }

  const filterColumn = async () => {
    if (isToggleFilter) {
      // make api call to save user settings
      let filterChoiceFields: ChoiceFieldDataType[] = []
      await saveChange((fieldData) => {
        filterChoiceFields = fieldData
      })

      // update Show Filters menu on the left side of the page
      const filters = fields
        .map((n) => {
          if (checkedIds.includes(n.id)) {
            const choiceField = filterChoiceFields.find((i) => i.key === n.fieldName)
            if (choiceField) {
              n.choices = choiceField.choices
            }
            return n
          }
          return null
        })
        .filter((n) => n)

      dispatch({ type: ActionType.SET_FILTERS, payload: filters })
      dispatch({ type: ActionType.SET_EXCLUSION_FILTERS, payload: filters })

      // close popup
      setOpenColumnFilter(false)

      return
    } else {
      await handleChange()
    }
    await dispatch({ type: ActionType.SET_COLUMN, payload: checkedIds })
    await setOpenColumnFilter(false)
  }

  const selectDeselect = (action: boolean) => {
    const idsToCheck = action
      ? isToggleFilter
        ? fields.filter((n) => !n.isDisabled).map((item) => item.id)
        : fields.map((item) => item.id)
      : isToggleFilter
        ? []
        : fields.filter((n) => n.isDisabled).map((item: Fields) => item.id)

    setCheckedIds(idsToCheck)
  }

  useEffect(() => {
    if (isToggleFilter) {
      setCheckedIds(filters.map((filter) => filter.fieldName))
    } else {
      setCheckedIds(column)
    }
  }, [column])

  const moveCard = useCallback(async (dragIndex: any, hoverIndex: any) => {
    // need to call array setter here
    let newOrderCopy = localFields.filter((n) => n.isDisabled === false)
    await setLocalFields((prevCards) => {
      newOrderCopy = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      }).filter((n) => n.isDisabled === false)
      return newOrderCopy
    })
    await setLocalFieldsFilteredData((prevCards) => {
      newOrderCopy = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      }).filter((n) => n.isDisabled === false)
      return newOrderCopy
    })
    const min = Math.min(dragIndex, hoverIndex)
    const max = Math.max(dragIndex, hoverIndex)
    for (let i = min; i <= max; i++) {
      newOrderCopy[i].filterColumnOrder = i
    }
    setLocalFields(newOrderCopy)
    setLocalFieldsFilteredData(newOrderCopy)
  }, [])

  const handleChange = () => {
    setLoading(true)
    const newData = localFields.map((item) => {
      return {
        id: item.uId,
        fieldName: item.fieldName,
        filterColumnOrder: item.filterColumnOrder
      }
    })

    return http
      .fetch({ method: 'put', path: '/setting/fields', data: { fields: newData } })
      .catch((error: any) => {
        toast(error.response?.data?.message || 'Internal server error', 'error')
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success')
        }
      })
  }

  const ColumnList = (field: Fields) => {
    const ref = useRef<HTMLDivElement>(null)

    const [{ handlerId }, drop] = useDrop({
      accept: 'column',
      // drop: async (droppedItem: { type: string; id: string; index: string | number }) => {
      //   // `Item with ID ${droppedItem.id} dropped on row with ID ${field.filterColumnOrder}`
      // },
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        }
      },
      hover(item: any, monitor) {
        if (!ref.current) {
          return
        }

        const dragIndex = item.index
        const hoverIndex = field.index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      }
    })

    const [{ isDragging }, drag] = useDrag({
      type: 'column',
      canDrag: () => !(field.isDisabled || isToggleFilter || isSearching),
      item: () => {
        return { id: field.id, index: field.index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging()
      })
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      <>
        <div
          className={`flex pb-3 gap-2 items-center`}
          ref={ref}
          data-handler-id={handlerId}
          style={{ zIndex: 100, opacity, cursor: isToggleFilter || isSearching ? 'grab' : 'unset' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                id={`id_${field.id}`}
                checked={checkedIds.includes(field.id)}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                onChange={() => handleCheckboxChange(field.id)}
                disabled={field.isDisabled}
              />
            }
            label={field.label}
          />
        </div>
      </>
    )
  }

  const filterAction = () => {
    http
      .fetch({ method: 'post', path: `setting/filters`, options: { params: { action: action } } })
      .catch(({ response }) => {
        toast(response?.data.message ?? 'Internal server error.', 'error')
        setConfirmModalOpen(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          toast(response?.data.message, 'success')
        }
        setConfirmModalOpen(false)
        getFields && getFields()
      })
  }

  const filterData = (value: string = '') => {
    const lowercasedValue = value.toLowerCase()
    const isSearching = !!value

    setIsSearching(isSearching)

    if (!isSearching) {
      if (isToggleFilter) {
        setFieldsFilteredData(fields.filter((i) => i.fieldName !== 'profile_image'))
      } else {
        setLocalFieldsFilteredData(localFields)
      }
      return
    }

    const filteredData = (isToggleFilter ? fields : localFields).filter(
      (item) => item.label.toLowerCase().includes(lowercasedValue) || (isToggleFilter && item.isDisabled)
    )

    if (isToggleFilter) {
      setFieldsFilteredData(filteredData)
    } else {
      setLocalFieldsFilteredData(filteredData)
    }
  }

  return (
    <>
      {loading && <Loading />}

      <div
        className={`fixed right-0 bg-white p-3 rounded-sm w-1/5 h-[calc(100vh-77px)] lg:h-[calc(100vh-45px)] top-20 shadow-lg md:w-9/12 z-50 transition-all duration-300 ease-in-out lg:w-72 lg:top-12 ${show ? '' : 'translate-x-full'}`}
      >
        <div className='flex flex-col mb-3 border-b gap-1'>
          <div className='flex justify-between items-center overflow-auto'>
            <h1 className='font-semibold'>{isToggleFilter ? 'Toggle Filter Columns' : 'Show/Hide Columns'}</h1>
            <MdClose size={'20px'} className=' cursor-pointer' onClick={() => setOpenColumnFilter(false)} />
          </div>
          <div key={`id_all`} className='flex flex-col gap-2 pb-2'>
            <div className='flex gap-2 items-center pb-2'>
              <label
                className='text-secondary cursor-pointer hover:underline underline-offset-2'
                onClick={() => {
                  selectDeselect(true)
                }}
              >
                Select All
              </label>
              <span className='text-secondary'>{'/'}</span>
              <label
                className='text-secondary cursor-pointer hover:underline underline-offset-2'
                onClick={() => {
                  selectDeselect(false)
                }}
              >
                Deselect All
              </label>
            </div>
            {isToggleFilter
              ? !isSuperAdmin(data?.roles) && (
                  <div className='flex gap-2 items-center pb-2'>
                    <label
                      className='text-secondary cursor-pointer hover:underline underline-offset-2'
                      onClick={() => {
                        setConfirmModalOpen(true)
                        setAction('filterableFields')
                        setActionLabel('Reset filter fields')
                      }}
                    >
                      Reset filter fields
                    </label>
                  </div>
                )
              : !isSuperAdmin(data?.roles) && (
                  <div className='flex gap-2 items-center pb-2'>
                    <label
                      className='text-secondary cursor-pointer hover:underline underline-offset-2'
                      onClick={() => {
                        setConfirmModalOpen(true)
                        setAction('filterColumnOrder')
                        setActionLabel('Reset column order')
                      }}
                    >
                      Reset column order
                    </label>
                    <label
                      className='text-secondary cursor-pointer hover:underline underline-offset-2'
                      onClick={() => {
                        setConfirmModalOpen(true)
                        setAction('displayFields')
                        setActionLabel('Reset display columns')
                      }}
                    >
                      Reset display columns
                    </label>
                  </div>
                )}
          </div>
        </div>
        <Autocomplete
          freeSolo
          options={[]}
          renderInput={(params) => <TextField {...params} label='Search' />}
          onInputChange={(e, value) => filterData(value)}
        />

        <div className={`overflow-x-hidden ${isToggleFilter !== true ? 'h-[calc(100%-138px)]' : ''}`}>
          {isToggleFilter ? (
            <>
              <DndProvider debugMode={true} backend={HTML5Backend}>
                <div>
                  <Virtuoso
                    key={'filter_fields_list'}
                    className={`${!isSuperAdmin(data?.roles) ? '!h-[calc(100vh-36.3vh)]' : '!h-[calc(100vh-32vh)]'} overflow-x-hidden`}
                    totalCount={fields.length}
                    data={fieldsFilteredData?.sort((a, b) => a.filterColumnOrder - b.filterColumnOrder)}
                    itemContent={(index, item) => <ColumnList index={index} {...item} />}
                  />
                </div>
              </DndProvider>
            </>
          ) : (
            <>
              {state.fields
                ?.filter((i) => i.isDisabled)
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`flex pb-3 gap-2 items-center ${isNaN(Number(item.uId)) ? '!cursor-not-allowed' : ''}`}
                      style={{ zIndex: 100 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={`id_${item.id}`}
                            checked={checkedIds.includes(item.id)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                            onChange={() => handleCheckboxChange(item.id)}
                            disabled={item.isDisabled}
                          />
                        }
                        label={item.label}
                      />
                    </div>
                  )
                })}
              <DndProvider debugMode={true} backend={HTML5Backend}>
                <div>
                  <Virtuoso
                    key={'display_fields_list'}
                    ref={columnListVirtuoso}
                    className={`${!isSuperAdmin(data?.roles) ? '!h-[calc(100vh-35vh)]' : '!h-[calc(100vh-31vh)]'} overflow-x-hidden`}
                    totalCount={localFieldsFilteredData.length}
                    data={localFieldsFilteredData}
                    itemContent={(index, item) => <ColumnList index={index} {...item} />}
                  />
                </div>
              </DndProvider>
            </>
          )}
        </div>
        <div className='flex absolute bottom-0 p-3 right-0 left-0 justify-center border-t bg-white'>
          <button className='bg-white btn-secondary w-[-webkit-fill-available]' onClick={filterColumn}>
            Submit
          </button>
        </div>
      </div>
      <div className={`${isConfirmModalOpen ? '' : 'hidden'}`}>
        <ConfirmationAlert
          title='Are you sure you perform this action ?'
          isConfirmModalOpen={isConfirmModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          ButtonText={'Confirm'}
          actionName={actionLabel}
          onAction={() => {
            filterAction()
          }}
        />
      </div>
    </>
  )
}
