import AdminSetting from '.'
import { Tabs, Tab, Box } from '@mui/material'
import { lazy, Suspense, useState } from 'react'

const BasicSettings = lazy(() => import('./BasicSettings'))
const Institute = lazy(() => import('./BasicSettings/Institute'))
const Session = lazy(() => import('./BasicSettings/Session'))
const Program = lazy(() => import('./BasicSettings/Program'))
const ConsulateJurisdiction = lazy(() => import('./BasicSettings/ConsulateJurisdiction'))
const AltConsulateJurisdiction = lazy(() => import('./BasicSettings/AltConsulateJurisdiction'))
const BatchVisaProcess = lazy(() => import('./BasicSettings/BatchVisaProcess'))
const StatusInfo = lazy(() => import('./BasicSettings/StatusInfo'))
const HousingConfirmationStatus = lazy(() => import('./BasicSettings/HousingConfirmationStatus'))

interface BasicSettingTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function BasicSettingTabPanel(props: BasicSettingTabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pt: 3, pl: 4 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export default function BasicSettingTab() {
  const [tabIndex, setTabIndex] = useState<number>(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  return (
    <>
      <div className='flex w-full min-h-[calc(100vh-77px)] lg:flex-wrap lg:min-h-full' data-cy='basic-setting-page'>
        <AdminSetting defaultOpen='settings' />
        <div className='w-full p-4 md:px-2'>
          <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs
                value={tabIndex}
                className='tab-list'
                onChange={handleChange}
                aria-label='basic setting tabs'
                variant='scrollable'
                allowScrollButtonsMobile
                sx={{ pl: 3 }}
              >
                <Tab label='Basic Setting' {...a11yProps(0)} data-cy='basic-setting' />
                <Tab label='Institutes' {...a11yProps(1)} data-cy='institutes' />
                <Tab label='Sessions' {...a11yProps(2)} data-cy='sessions' />
                <Tab label='Academic Programs' {...a11yProps(3)} data-cy='academic-programs' />
                <Tab label='Consulate Jurisdiction' {...a11yProps(4)} data-cy='consulate-jurisdiction' />
                <Tab label='Alt Consulate Jurisdiction' {...a11yProps(5)} data-cy='alt-consulate-jurisdiction' />
                <Tab label='Batch Visa Process' {...a11yProps(6)} data-cy='batch-visa-process' />
                <Tab label='Status Info' {...a11yProps(7)} data-cy='status-info' />
                <Tab label='Housing Confirmation Status' {...a11yProps(8)} data-cy='housing-confirmation-status' />
              </Tabs>
            </Box>
            <BasicSettingTabPanel value={tabIndex} index={0}>
              <Suspense>
                <BasicSettings />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={1}>
              <Suspense>
                <Institute />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={2}>
              <Suspense>
                <Session />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={3}>
              <Suspense>
                <Program />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={4}>
              <Suspense>
                <ConsulateJurisdiction />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={5}>
              <Suspense>
                <AltConsulateJurisdiction />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={6}>
              <Suspense>
                <BatchVisaProcess />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={7}>
              <Suspense>
                <StatusInfo />
              </Suspense>
            </BasicSettingTabPanel>
            <BasicSettingTabPanel value={tabIndex} index={8}>
              <Suspense>
                <HousingConfirmationStatus />
              </Suspense>
            </BasicSettingTabPanel>
          </Box>
        </div>
      </div>
    </>
  )
}
