import { Autocomplete, Button, Chip, TextField } from '@mui/material'
// import TinyEditor from 'Components/TinyEditor'
import CKEditors from 'Components/CKEditor'
import { Link, useLocation, useParams } from 'react-router-dom'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FileUpload from 'Components/FileUpload'
import { FaDownload, FaSave, FaTrashAlt } from 'react-icons/fa'
import { downloadFile, ImpersonateInitiated, linkify } from 'CommonJS/common'
import styled from '@emotion/styled'
import { ChangeEvent, lazy, Suspense, useContext, useEffect, useState } from 'react'
import { DocumentType, StepInfo } from './type'
import http from 'CommonJS/http'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import { AppContextType } from 'Context/AppTypes'
import { AppContext } from 'Context/AppContext'
import { UserContext } from 'Context/UserContext'
import { StudentVisaPortalInfo } from 'Components/VisaDashboard/type'
import { AxiosResponse } from 'axios'
import { isAdmin, isSuperAdmin } from 'CommonJS/roleHelper'
const StudentHousingConfirmationStatus = lazy(() => import('./StudentHousingConfirmationStatus'))

export interface NotesFooterBlockProps {
  /**
   *
   * @param showToast - default true
   * @param defaultStepInfo - default null
   * @returns {boolean}
   */
  canPerformAction: (showToast?: boolean, defaultStepInfo?: null) => boolean
  //   loading: boolean
  setLoading: (value: boolean) => void
  visaPortalStepInfo: StepInfo | StudentVisaPortalInfo | undefined
  getVisaPortalStepInfo: () => Promise<void>
}

interface UserType {
  id: number
  name: string
}

interface UserListType extends UserType {
  roles: []
  taggedBy: UserType
}

interface StatusInfoType {
  id: number
  name: string
}

export default function NotesFooterBlock({
  canPerformAction,
  setLoading,
  visaPortalStepInfo,
  getVisaPortalStepInfo
}: NotesFooterBlockProps) {
  const { data } = useContext(UserContext)
  const [actionLabel, setActionLabel] = useState<string>('')
  const [deleteVal, setDeleteVal] = useState<any>()
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const { multipleUploadFile } = useContext(AppContext) as AppContextType
  const [users, setUsers] = useState<UserListType[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserListType[]>([])
  const [statusInfos, setStatusInfos] = useState<StatusInfoType[]>([])
  const [selectedStatus, setSelectedStatus] = useState<StatusInfoType[]>([])
  const [AbortAllController] = useState(new AbortController())

  const match = useLocation()
  const { id } = useParams()
  let studentId: any
  if (/\/(visa|student)-dashboard/.test(match.pathname) && id) {
    studentId = id
  } else {
    studentId = data?.student?.id
  }

  useEffect(() => {
    if (ImpersonateInitiated && AbortAllController) {
      AbortAllController?.abort()
    }
  }, [ImpersonateInitiated])

  useEffect(() => {
    const init = async () => {
      setTimeout(async () => {
        await getUserList()
        await getStatusInfoList()
        await getInternalNoteTagUsers()
        await getStatusInfo()
      }, 1800)
    }
    if (isAdmin(data.roles) || data.roles.includes('basic_user')) {
      init()
    }

    return () => {
      // On Un Mount
      if (typeof AbortAllController.abort === 'function') {
        AbortAllController.abort()
      }
    }
  }, [])

  const getUserList = async () => {
    return await http
      .fetch({ path: 'internalNoteTagUserList', options: { signal: AbortAllController.signal } })
      .catch((error) => {
        if (error?.code === 'ERR_CANCELED') return
        toast(error?.response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          setUsers(response.data.users)
        }
      })
  }

  const getInternalNoteTagUsers = async () => {
    await http
      .fetch({ path: `students/${studentId}/internalNoteTagUser`, options: { signal: AbortAllController.signal } })
      .catch((error) => {
        if (error?.code === 'ERR_CANCELED') return
        toast(error?.response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: void | AxiosResponse<{ success: boolean; internalNoteTagUsers: UserListType[] }>) => {
        if (response && response.status === 200) {
          const internalNoteTagUsers = response.data.internalNoteTagUsers
          // Sort to keep logged in user tag first
          setSelectedUsers(
            internalNoteTagUsers.toSorted((a, b) => {
              if (a.id === data.id) return -1
              if (b.id === data.id) return 1
              return 0
            })
          )
        }
      })
  }
  const getStatusInfoList = async () => {
    return await http
      .fetch({ path: 'statusInfo', options: { signal: AbortAllController.signal } })
      .catch((error) => {
        if (error?.code === 'ERR_CANCELED') return
        toast(error?.response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: any) => {
        if (response && response.status === 200) {
          setStatusInfos(response.data.statusInfos)
        }
      })
  }

  const getStatusInfo = async () => {
    await http
      .fetch({ path: `students/${studentId}/statusInfo`, options: { signal: AbortAllController.signal } })
      .catch((error) => {
        if (error?.code === 'ERR_CANCELED') return
        toast(error?.response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: void | AxiosResponse<{ success: boolean; statusInfos: StatusInfoType[] }>) => {
        if (response && response.status === 200) {
          const statusInfos = response.data.statusInfos
          // Sort to keep logged in user tag first
          setSelectedStatus(
            statusInfos.toSorted((a, b) => {
              if (a.id === data.id) return -1
              if (b.id === data.id) return 1
              return 0
            })
          )
        }
      })
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>, document: any, column: string) => {
    try {
      event.preventDefault()
      const imageFile = event.target.files
      await setLoading(true)
      if (imageFile) {
        const fileRes = await multipleUploadFile(imageFile, id)
        if (fileRes && ['supporting_documents'].includes(column)) {
          if (column == 'supporting_documents') {
            const addedDocumentId = fileRes.document.map((item: any) => item.id)
            const documents = document.map((item: DocumentType) => item.id)
            const IDs = [...addedDocumentId, ...documents]
            onSaveUserInfo(IDs, column)
          } else {
            onSaveUserInfo(fileRes.document[0].id, column)
          }
        } else {
          onSaveUserInfo(fileRes.document[0]?.id, column)
        }
      }
      await setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onSaveUserInfo = async (content: any, param: string) => {
    try {
      const info = {
        [param]: content
      }
      await setLoading(true)
      await http
        .fetch({
          path: `/students/${studentId}`,
          method: 'put',
          data: info
        })
        .then(async (response) => {
          if (response && response.status === 200) {
            getVisaPortalStepInfo()
            toast(response?.data?.message, 'success')
          }
          setLoading(false)
        })
    } catch (error) {
      setLoading(false)
      toast('Internal server error.', 'error')
    }
  }

  // * Delete document is performed directly on the save step info api itself.
  // const deleteDocument = async (document: number) => {
  //   http
  //     .fetch({ method: 'delete', path: `document/${document}` })
  //     .catch(() => {
  //       toast('Internal server error.', 'error')
  //     })
  //     .then((response) => {
  //       if (response && response.status === 200) {
  //         toast(response?.data?.message, 'success')
  //       }
  //     })
  // }

  const handleDelete = async () => {
    if (!canPerformAction()) return
    const IDs = visaPortalStepInfo?.supportingDocuments.map((item: any) => item.id)
    const removedId = IDs?.filter((item) => item !== deleteVal?.id)
    await onSaveUserInfo(removedId, 'supporting_documents')
    // deleteDocument(deleteVal.id)
    setConfirmModalOpen(false)
  }

  const internalNoteTagUpdate = async () => {
    if (!canPerformAction()) return
    const taggedTo = selectedUsers.map((o) => o.id)

    setLoading(true)
    await http
      .fetch({ method: 'post', path: `students/${studentId}/internalNoteTagUser`, data: { taggedTo: taggedTo } })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success', {
            key: 'tagUpdated',
            preventDuplicate: true
          })
          getInternalNoteTagUsers()
        }
      })
  }

  const tagOptionDisabled = (option: UserListType): boolean => {
    const selected = selectedUsers.find((o) => o.id === option.id)
    if (selected && selected?.taggedBy) {
      return ![selected?.id, selected?.taggedBy?.id].includes(data.id)
    }

    return false
  }

  const statusInfoUpdate = async () => {
    if (!canPerformAction()) return
    const statusInfoId = selectedStatus.map((o) => o.id)
    setLoading(true)
    await http
      .fetch({ method: 'post', path: `students/${studentId}/statusInfo`, data: { statusInfoId: statusInfoId } })
      .catch(({ response }) => {
        setLoading(false)
        toast(response?.data.message ?? 'Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          toast(response?.data?.message || 'Successfully updated data', 'success', {
            key: 'statusUpdated',
            preventDuplicate: true
          })
          getStatusInfo()
        }
      })
  }

  return (
    <>
      {isAdmin(data.roles) || data.roles.includes('basic_user') ? (
        <div className='grid grid-cols-2 lg:grid-cols-1 pb-3 gap-7 h-max'>
          <div className='overflow-hidden pb-3 h-full'>
            <div className='flex items-center flex-wrap gap-2 mb-2'>
              <h4 className='text-xl font-bold'>Note to Student </h4>
              <Link to='#' className='text-secondary'>
                (visible to student)
              </Link>
            </div>

            <div className='rounded-md bg-white pt-4 mb-3 relative h-[calc(48rem-35px)] note-student'>
              <CKEditors
                initialValue={visaPortalStepInfo?.studentNote || ''}
                type={'student_note'}
                label={'Student Note'}
                callSubmit={onSaveUserInfo}
                isView={false}
                variable={[]}
                reSize={true}
                customOptions={{ disabled: !canPerformAction(false) ? 'Action cannot be performed on archived students.' : false }}
                height={550}
                classes={{
                  labelBlock: 'px-4',
                  editorBlock: 'px-4 h-[calc(100%-10%)] md:h-[calc(100%-55%)] !static',
                  submitBtnBlock: '!absolute px-4 !m-[unset]'
                }}
              />
            </div>
          </div>

          <div className='mb-5 h-full'>
            <div className='flex items-center mb-2 flex-wrap gap-2 '>
              <h4 className='text-xl font-bold'>Housing Confirmation Status </h4>
              <Link to='#' className='text-secondary'>
                (Private admin only)
              </Link>
            </div>
            <div className='rounded-md bg-white py-4 relative h-[calc(11rem-35px)]'>
              <Suspense>
                <StudentHousingConfirmationStatus canPerformAction={canPerformAction} setLoading={setLoading} />
              </Suspense>
            </div>

            <div className='flex items-center mb-2 flex-wrap gap-2 '>
              <h4 className='text-xl font-bold'> Internal Notes </h4>
              <Link to='#' className='text-secondary'>
                (Private admin only)
              </Link>
            </div>

            <div className='rounded-md bg-white py-4 relative h-[calc(37rem-35px)]'>
              <div className='flex justify-between mb-3'>
                <div className='w-full flex flex-col mx-3 gap-3'>
                  <Autocomplete
                    className=''
                    options={users}
                    disabled={!canPerformAction(false)}
                    limitTags={!canPerformAction(false) ? undefined : 3}
                    disableCloseOnSelect={true}
                    disableClearable={!isSuperAdmin(data.roles)}
                    getOptionLabel={(option: UserListType) => option.name || ''}
                    sx={{ width: '95%' }}
                    onChange={(event, newValue) => {
                      // Sort to keep logged in user tag first
                      setSelectedUsers(
                        newValue.toSorted((a, b) => {
                          if (a.id === data.id) return -1
                          if (b.id === data.id) return 1
                          return 0
                        })
                      )
                    }}
                    multiple
                    getOptionDisabled={tagOptionDisabled}
                    value={selectedUsers}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    renderInput={(params) => <TextField {...params} label='Tag User' />}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => {
                        let taggedBy = 'You'
                        if (option?.taggedBy && option?.taggedBy?.id !== data.id) {
                          taggedBy = option.taggedBy.name
                        }
                        const deleteAble = !tagOptionDisabled(option)

                        return (
                          <Chip
                            {...getTagProps({ index })}
                            color={option.id === data.id ? 'warning' : undefined}
                            onDelete={deleteAble ? getTagProps({ index }).onDelete : undefined}
                            sx={{
                              height: 'auto',
                              '& .MuiChip-label': {
                                display: 'block',
                                whiteSpace: 'normal'
                              }
                            }}
                            label={
                              <span className='flex flex-col'>
                                <span>{option.name}</span>
                                <small>
                                  Tagged By: <b>{taggedBy}</b>
                                </small>
                              </span>
                            }
                            key={`${option.id}_${index}`}
                            title={`Tagged By: ${taggedBy}`}
                          />
                        )
                      })
                    }}
                  />
                  <button
                    type='submit'
                    className='btn-primary flex items-center justify-between gap-x-3 max-w-32'
                    data-cy='save-btn'
                    title='Save tag users change'
                    onClick={internalNoteTagUpdate}
                  >
                    Save <FaSave className='w-4' />
                  </button>
                </div>
              </div>
              <CKEditors
                initialValue={visaPortalStepInfo?.advisorNote || ''}
                type={'advisor_note'}
                label={'Advisor Note'}
                callSubmit={onSaveUserInfo}
                isView={false}
                variable={[]}
                reSize={true}
                customOptions={{ disabled: !canPerformAction(false) ? 'Action cannot be performed on archived students.' : false }}
                height={420}
                classes={{
                  labelBlock: 'px-4',
                  editorBlock: 'px-4 h-[calc(100%-35%)] md:h-[calc(100%-55%)] !static note-advisor',
                  submitBtnBlock: '!absolute px-4 '
                }}
              />
            </div>
          </div>

          <div className='/h-1/5'>
            <div className='flex items-center mb-4 flex-wrap gap-2'>
              <h4 className='text-xl font-bold'> Supporting Documents </h4>
              <span className=' text-secondary'>(upload)</span>
            </div>

            <div className='rounded-md bg-white p-4 h-[20rem] overflow-hidden'>
              <div className='flex w-full justify-end'>
                <Button component='label' role={undefined} variant='contained' tabIndex={-1} startIcon={<CloudUploadIcon />}>
                  Upload file
                  <VisuallyHiddenInput
                    type='file'
                    multiple
                    onChange={(event) => handleFileChange(event, visaPortalStepInfo?.supportingDocuments, 'supporting_documents')}
                    onClick={(e) => {
                      if (!canPerformAction()) e.preventDefault()
                    }}
                  />
                </Button>
              </div>
              <div className='overflow-auto h-4/5 mt-4'>
                <table className='w-full'>
                  <tbody>
                    {visaPortalStepInfo?.supportingDocuments?.length === 0 && (
                      <tr>
                        <td colSpan={2}>No documents</td>
                      </tr>
                    )}
                    {visaPortalStepInfo?.supportingDocuments.map((value) => {
                      const url = value.path
                      return (
                        <tr key={value.id}>
                          <td>
                            <FileUpload fileUrl={value.path} size={43} isViewOnly={true} />
                          </td>
                          <td>
                            <div className='flex gap-4 w-max mx-auto'>
                              <FaDownload className='size-5 cursor-pointer' onClick={() => downloadFile(url)} />
                              <FaTrashAlt
                                className='text-red-600 size-5 cursor-pointer'
                                onClick={() => {
                                  setActionLabel('Delete Document')
                                  setConfirmModalOpen(true)
                                  setDeleteVal(value)
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className='/h-1/5'>
            <div className='flex items-center mb-4 flex-wrap gap-2'>
              <h4 className='text-xl font-bold'> Application Status Info </h4>
              <span className=' text-secondary'>(Private admin only)</span>
            </div>

            <div className='rounded-md bg-white p-4 h-[20rem] overflow-hidden'>
              <div className='w-full flex flex-col mx-3 gap-3'>
                <Autocomplete
                  className=''
                  options={statusInfos}
                  disabled={!canPerformAction(false)}
                  limitTags={!canPerformAction(false) ? undefined : 3}
                  disableCloseOnSelect={true}
                  disableClearable={!isSuperAdmin(data.roles)}
                  getOptionLabel={(option: StatusInfoType) => option.name || ''}
                  sx={{ width: '95%' }}
                  onChange={(event, newValue) => {
                    // Sort to keep logged in user tag first
                    setSelectedStatus(
                      newValue.toSorted((a, b) => {
                        if (a.id === data.id) return -1
                        if (b.id === data.id) return 1
                        return 0
                      })
                    )
                  }}
                  multiple
                  value={selectedStatus}
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  renderInput={(params) => <TextField {...params} label='Status Info' />}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => {
                      return (
                        <Chip
                          {...getTagProps({ index })}
                          onDelete={getTagProps({ index }).onDelete}
                          sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal'
                            }
                          }}
                          label={
                            <span className='flex flex-col'>
                              <span>{option.name}</span>
                            </span>
                          }
                          key={`${option.id}_${index}`}
                        />
                      )
                    })
                  }}
                />
                <button
                  type='submit'
                  className='btn-primary flex items-center justify-between gap-x-3 max-w-32'
                  data-cy='save-btn'
                  title='Save tag users change'
                  onClick={statusInfoUpdate}
                >
                  Save <FaSave className='w-4' />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : data.roles.includes('student') ? (
        <>
          <div className='grid grid-cols-1 lg:grid-cols-1 pb-3 gap-7'>
            <div className='mb-3 '>
              <div className='flex items-center flex-wrap gap-2 mb-2'>
                <h4 id='advisor-note' className='text-xl font-bold'>
                  Note from your Admission Advisor
                </h4>
              </div>

              <div className='rounded-md bg-white p-4 mb-3 h-80 text-gray'>
                <iframe
                  className='size-full'
                  sandbox='allow-scripts allow-popups allow-downloads allow-modals' // to allow link tag network request. otherwise it will be canceled.
                  srcDoc={
                    visaPortalStepInfo?.studentNote
                      ? linkify(
                          `<html> <head><link rel="stylesheet" href="/css/ckeditor5-content.css" /></head> <body> <div class="ck-content"> ${visaPortalStepInfo?.studentNote} </div> </body> </html>`,
                          { target: '_blank' }
                        ) + "<style> body{font-family:'Helvetica Neue',sans-serif;}</style>"
                      : '<h3 style="color: rgb(31, 41, 55); font-size: 20px;">No Note Found</h3>'
                  }
                ></iframe>
              </div>
            </div>
          </div>
          <div className='flex items-center mb-4 flex-wrap gap-2'>
            <h4 className='text-xl font-bold'> Supporting Documents </h4>
            <span className=' text-secondary'>(upload)</span>
          </div>
          <div className='rounded-md bg-white p-4 h-[19rem] overflow-hidden'>
            <div className='flex w-full justify-end'>
              <Button component='label' role={undefined} variant='contained' tabIndex={-1} startIcon={<CloudUploadIcon />}>
                Upload file
                <VisuallyHiddenInput
                  type='file'
                  multiple
                  onChange={(event) => handleFileChange(event, visaPortalStepInfo?.supportingDocuments, 'supporting_documents')}
                  onClick={(e) => {
                    if (!canPerformAction()) e.preventDefault()
                  }}
                />
              </Button>
            </div>
            <div className='overflow-auto h-4/5 mt-4'>
              <table className='w-full'>
                <tbody>
                  {visaPortalStepInfo?.supportingDocuments?.length === 0 && (
                    <tr>
                      <td colSpan={2}>No documents</td>
                    </tr>
                  )}
                  {visaPortalStepInfo?.supportingDocuments.map((value) => {
                    const url = value.path
                    return (
                      <tr key={value.id}>
                        <td>
                          <FileUpload fileUrl={value.path} size={43} isViewOnly={true} />
                        </td>
                        <td>
                          <div className='flex gap-4 w-max mx-auto'>
                            <FaDownload className='size-5 cursor-pointer' onClick={() => downloadFile(url)} />
                            <FaTrashAlt
                              className='text-red-600 size-5 cursor-pointer'
                              onClick={() => {
                                setActionLabel('Delete Document')
                                setConfirmModalOpen(true)
                                setDeleteVal(value)
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className='grid grid-cols-1 lg:grid-cols-1 pb-3 gap-7'>
          <div className='mb-3 '>
            <div className='flex items-center flex-wrap gap-2 mb-2'>
              <h4 id='advisor-note' className='text-xl font-bold'>
                Note from your Admission Advisor
              </h4>
            </div>

            <div className='rounded-md bg-white p-4 mb-3 h-80 text-gray'>
              <iframe
                className='size-full'
                sandbox='allow-scripts allow-popups allow-downloads allow-modals' // to allow link tag network request. otherwise it will be canceled.
                srcDoc={
                  visaPortalStepInfo?.studentNote
                    ? linkify(
                        `<html> <head><link rel="stylesheet" href="/css/ckeditor5-content.css" /></head> <body> <div class="ck-content"> ${visaPortalStepInfo?.studentNote} </div> </body> </html>`,
                        { target: '_blank' }
                      ) + "<style> body{font-family:'Helvetica Neue',sans-serif;}</style>"
                    : '<h3 style="color: rgb(31, 41, 55); font-size: 20px;">No Note Found</h3>'
                }
              ></iframe>
            </div>
          </div>
        </div>
      )}

      <ConfirmationAlert
        title={'Are you sure you perform this action ?'}
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        ButtonText={'Yes I am sure'}
        actionName={actionLabel}
        onAction={() => {
          if (actionLabel == 'Delete Document') {
            handleDelete()
          }
        }}
      />
    </>
  )
}
