import React, { useContext, useEffect, useMemo, useState } from 'react'
import http from 'CommonJS/http'
import './index.css'
import { ReactComponent as NewApplication } from 'assets/images/new-application.svg'
import { ReactComponent as WithdrawnApplication } from 'assets/images/withdrawn-application.svg'
import { ReactComponent as CompleteApplication } from 'assets/images/complete-application.svg'
import { ReactComponent as TotalApplication } from 'assets/images/total-application.svg'
import { Autocomplete, createFilterOptions, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Loading from 'Components/Loading'
import { AppContext } from 'Context/AppContext'
import { AppContextType, Session } from 'Context/AppTypes'
import { DataItem, IconBgMap, IconMap, valueType } from './type'
import { toast } from 'Components/Toast/toast'
import { Link } from 'react-router-dom'
import { MdOutlinePendingActions, MdPayment } from 'react-icons/md'
import { RiBillLine } from 'react-icons/ri'
import { FaRegMoneyBillAlt } from 'react-icons/fa'
import { isStudyAbroadAdvisor } from 'CommonJS/roleHelper'
import { UserContext } from 'Context/UserContext'

export default function AdminDashboardV2() {
  const [data, setValue] = useState<Partial<valueType>>({
    instituteId: '',
    programId: '',
    sendingInstitution: '',
    yearId: '',
    homeCollegeId: ''
  })

  interface SendingInstitutionOptionsType {
    text?: string
    value?: string
  }

  const [sendingInstitutionOptions, setSendingInstitution] = useState<SendingInstitutionOptionsType[]>([])

  const [loading, setLoading] = useState<boolean>(false)

  const iconBgMap: IconBgMap = {
    ai_outline_appstore_add: 'bg-primary/15',
    md_settings_applications: 'bg-blue-600/15',
    go_shield_check: 'bg-green-600/15',
    bi_money_withdraw: 'bg-red-600/15',
    go_shield_accepted: 'bg-green-300/15',
    go_shield_condition_accepted: 'bg-orange-300/15',
    go_shield_committed: 'bg-blue-900/15',
    md_outline_pending_actions: 'bg-[#FF6347]/15',
    md_payment: 'bg-[#32CD32]/15',
    ri_bill_line: 'bg-[#FF4500]/15',
    fa_reg_money_bill_alt: 'bg-[#228B22]/15'
  }
  const iconMap: IconMap = {
    ai_outline_appstore_add: <NewApplication className='w-6' />,
    md_settings_applications: <TotalApplication className='fill-blue-600 w-6' />,
    go_shield_check: <CompleteApplication className='fill-green-600 w-6' />,
    bi_money_withdraw: <WithdrawnApplication className='fill-red-600 w-6' />,
    go_shield_accepted: <CompleteApplication className='w-6 [&_*]:!stroke-green-300' />,
    go_shield_condition_accepted: <CompleteApplication className='w-6 [&_*]:!stroke-orange-600' />,
    go_shield_committed: <CompleteApplication className='w-6 [&_*]:!stroke-blue-900' />,
    md_outline_pending_actions: <MdOutlinePendingActions className='w-6 fill-[#FF6347]' size={24} />,
    md_payment: <MdPayment className='w-6 fill-[#32CD32]' size={24} />,
    ri_bill_line: <RiBillLine className='w-6 fill-[#FF4500]' size={24} />,
    fa_reg_money_bill_alt: <FaRegMoneyBillAlt className='w-6 fill-[#228B22]' size={24} />
  }

  const { institutes, sessions, programs, years, homeColleges, getInstitute, getPrograms, getSession, getYears, getHomeCollege } =
    useContext(AppContext) as AppContextType
  const [stats, setData] = useState<DataItem[]>([])
  const [filterQueryParams, setFilterQueryParams] = useState('')
  const { data: loginUser } = useContext(UserContext)
  const [withNoSessions, setWithNoSessions] = useState<Session[]>()

  useEffect(() => {
    const init = async () => {
      await setLoading(true)
      if (institutes?.length === 0) getInstitute()
      if (programs?.length === 0) getPrograms()
      if (sessions?.length === 0) getSession()
      if (years?.length === 0) getYears()
      if (!isStudyAbroadAdvisor(loginUser.roles) && homeColleges?.length === 0) getHomeCollege()
      // await setLoading(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (sessions.length > 0) {
      setWithNoSessions([...sessions, { id: 0, name: '' }])
    }
  }, [sessions])

  useEffect(() => {
    const init = async () => {
      let filterableData = {}
      Object.keys(data).forEach((i) => {
        const val = (data as Array<string>)[i as any]
        if (val) {
          filterableData = {
            ...(filterableData as any),
            [i]: val
          }
        }
        return val ? { [i]: val } : null
      })
      const newQueryParams = new URLSearchParams(filterableData as any)
      setFilterQueryParams(newQueryParams.toString())

      await getDashboard()
    }
    init()
  }, [data])

  const getDashboard = () => {
    const paramsStringObject: Record<string, string> = {}
    setLoading(true)
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          paramsStringObject[key] = String(value)
        }
      })
    }
    http
      .fetch({ path: 'stats-v2', options: { params: paramsStringObject } })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          const rspData = response.data.data
          if (Object.keys(rspData).includes('sendingInstitutionOptions') && rspData?.sendingInstitutionOptions?.length > 0) {
            setSendingInstitution(rspData.sendingInstitutionOptions)
          }
          delete rspData?.sendingInstitutionOptions
          setData(Object.values(rspData))
        }
        setTimeout(() => {
          setLoading(false)
        }, 600)
      })
  }

  const getItem = (item: DataItem, sessionId?: number): any => {
    let filterUrl = '/admin/student'
    if (sessionId) {
      filterUrl += '?sessionId=' + sessionId + '&'
    }

    if (!filterUrl.endsWith('&')) {
      filterUrl += '?'
    }

    const hasFilterQueryParams = filterQueryParams && filterQueryParams.trim() !== ''
    if (item.filter) {
      filterUrl += `${item.filter.key}=${item.filter.value}${hasFilterQueryParams ? '&' + filterQueryParams : ''}`
    } else {
      filterUrl += `${
        item.applicationStatus
          ? 'application_status=' + item.applicationStatus + (hasFilterQueryParams ? '&' + filterQueryParams : '')
          : '' + filterQueryParams
      }`
    }
    return filterUrl
  }

  const totalNewApplications = useMemo(() => {
    let finalTotal = 0

    stats?.forEach((stat) => {
      if (stat.name === 'New Applications' && Array.isArray(stat.sessionTotal)) {
        stat.sessionTotal.forEach((sTotal) => {
          finalTotal += Number(sTotal?.total ?? 0)
        })
      }
    })
    return finalTotal
  }, [stats])

  return (
    <div className='px-7 w-full lg:px-4 py-5 relative' data-cy='dashboard-page'>
      <h1 className='text-3xl font-bold mb-7 md:mb-4'>Dashboard</h1>
      {loading && <Loading />}

      <div className='flex gap-4 justify-end mb-5 md:flex-col md:gap-1'>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-44'>
          <InputLabel id='admin-dashboard-sending-institution-filter-label'>Sending Institution</InputLabel>
          <Select
            labelId='admin-dashboard-sending-institution-filter-label'
            id='admin-dashboard-sending-institution-filter'
            data-cy='sending-institution'
            value={data?.sendingInstitution}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                sendingInstitution: e.target.value
              }))
            }}
            autoWidth
            label='Sending Institution'
          >
            <MenuItem value=''>None</MenuItem>
            {sendingInstitutionOptions?.map((option) => (
              <MenuItem key={option?.value} value={option?.value}>
                {option?.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-institute-filter-label'>Institutes</InputLabel>
          <Select
            labelId='admin-dashboard-institute-filter-label'
            id='admin-dashboard-institute-filter'
            data-cy='institutes'
            value={data?.instituteId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                instituteId: e.target.value
              }))
            }}
            autoWidth
            label='Institutes'
          >
            <MenuItem value=''> None</MenuItem>
            {institutes?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-program-filter-label'>Programs</InputLabel>
          <Select
            labelId='admin-dashboard-program-filter-label'
            id='admin-dashboard-program-filter'
            data-cy='programs'
            value={data?.programId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                programId: e.target.value
              }))
            }}
            autoWidth
            label='Programs'
          >
            <MenuItem value=''> None</MenuItem>
            {programs?.map((institute) => (
              <MenuItem key={institute.id} value={institute.id}>
                {institute.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-36'>
          <InputLabel id='admin-dashboard-year-filter-label'>Year</InputLabel>
          <Select
            labelId='admin-dashboard-year-filter-label'
            id='admin-dashboard-year-filter'
            data-cy='years'
            value={data?.yearId}
            onChange={(e) => {
              setValue((prevValue) => ({
                ...prevValue,
                yearId: e.target.value
              }))
            }}
            autoWidth
            label='year'
          >
            <MenuItem value=''>None</MenuItem>
            {years?.map((year) => (
              <MenuItem key={year.id} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!isStudyAbroadAdvisor(loginUser.roles) && (
          <Autocomplete
            sx={{ m: 1, minWidth: 80 }}
            className='!min-w-60'
            id='admin-dashboard-homecollege-filter'
            data-cy='homecollege'
            options={homeColleges}
            getOptionLabel={(option: any) => option.name || ''}
            value={homeColleges.find((college) => college.id === data?.homeCollegeId) || null}
            disableCloseOnSelect={true}
            onChange={(event, newValue) => {
              setValue((prevValue) => ({
                ...prevValue,
                homeCollegeId: newValue ? newValue.id : ''
              }))
            }}
            filterOptions={createFilterOptions({
              limit: 100,
              trim: true
            })}
            componentsProps={{ popper: { id: 'course-schedule-home-college-popup' } }}
            renderInput={(params) => <TextField {...params} label='Home University' />}
          />
        )}
      </div>

      <div className='mb-7 md:mb-4'>
        <h3 className='text-2xl font-bold mb-3 text-[clamp(1rem,5vw,1.5rem)]'>New Applications: {totalNewApplications ?? 0}</h3>
        <Divider />
      </div>

      <div id='dashboard-v2' className='grid grid-cols-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3  gap-7'>
        {withNoSessions?.map((session) => {
          return (
            <React.Fragment key={`session_table_${session?.id ?? '_no_session'}`}>
              <table>
                <thead>
                  <tr>
                    <th colSpan={2}>{String(session?.name ?? '').trim() !== '' ? session?.name : 'No Session'}</th>
                  </tr>
                </thead>
                <tbody>
                  {stats?.length === 0 && (
                    <tr>
                      <td colSpan={2} className='text-center'>
                        No data found
                      </td>
                    </tr>
                  )}
                  {stats?.map((item: DataItem, index: number) => {
                    const sessionData = (item.sessionTotal ?? []).find((itm: { sessionId: number; total: number }) =>
                      session?.id ? itm.sessionId == session?.id : !itm.sessionId
                    )
                    return (
                      <tr key={`${session?.id ?? 'no_session'}_${index}_${iconBgMap[item.icon]}`}>
                        <td>
                          <Link to={getItem(item)} key={index} className='flex items-center gap-3'>
                            <div className={`icon `}>{iconMap[item?.icon]}</div>
                            {item.name}
                          </Link>
                        </td>
                        <td>{sessionData && sessionData?.total ? sessionData.total : 0}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
