import http from 'CommonJS/http'
import { FaSave } from 'react-icons/fa'
import { DataItem, FormDataFields } from './type'
import Loading from 'Components/Loading'
import { useForm } from 'react-hook-form'
import Select from '@mui/material/Select'
import { toast } from 'Components/Toast/toast'
// import { Editor } from '@tinymce/tinymce-react'
import { IoIosArrowBack } from 'react-icons/io'
import { AppContext } from 'Context/AppContext'
// import { Editor as TinyMCEEditor } from 'tinymce'
import { AppContextType } from 'Context/AppTypes'
import AdminSetting from 'Components/AdminSetting'
import OutlinedInput from '@mui/material/OutlinedInput'
import { removeBlankPairs } from 'CommonJS/common'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Autocomplete, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, TextField, createFilterOptions } from '@mui/material'
// import { TinyMcePlugins, TinyMceToolbar, TinyMceCustomizationOptions } from 'Components/TinyEditor/pluginAndTollbar'
import HomeCollegeHelp from '../HomeCollegeHelp'
import { applicationStatusFilters, ApplicationStatusFilterType } from 'CommonJS/studentCommon'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from 'ckeditor5'
import { CKEditorConfig } from 'Components/CKEditor/pluginAndTollbar'
import 'ckeditor5/ckeditor5.css'
import { Editor as CKEditorType } from '@ckeditor/ckeditor5-core'

export default function InsurancePortalAddEdit() {
  interface Errors {
    title?: string[]
    content?: string[]
    message?: string
    institute?: string
  }
  const [error, setError] = useState<Errors>({})
  const editorRef = useRef<CKEditorType | null>(null)
  const navigate = useNavigate()
  const { id } = useParams()

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
      }
    }
  }

  const [data, setData] = useState<Partial<DataItem>>()

  const defaultValues: FormDataFields = {
    title: '',
    content: '',
    institute: [],
    session: [],
    program: [],
    student: [],
    year: [],
    sendingInstitution: [],
    homeCollege: [],
    applicationStatus: []
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setValue
  } = useForm<FormDataFields>({ defaultValues })

  const [selected_institute, setSelectedInstitute] = useState<number[]>([])
  const [selected_session, setSelectedSession] = useState<number[]>([])
  const [selected_year, setSelectedYear] = useState<number[]>([])
  const [selected_program, setSelectedProgram] = useState<number[]>([])
  const [selected_sending_institution, setSelectedSendingInstitution] = useState<string[]>([])
  const [selected_home_college, setSelectedHomeCollege] = useState<number[]>([])
  const [selectedStudentId, setSelectedStudent] = useState<number[]>([])
  const [selected_application_status, setSelectedApplicationStatus] = useState<ApplicationStatusFilterType['value'][]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const {
    institutes,
    programs,
    sessions,
    years,
    sendingInstitutions,
    homeColleges,
    getInstitute,
    getPrograms,
    getSession,
    getYears,
    getSendingInstitution,
    getHomeCollege
  } = useContext(AppContext) as AppContextType

  useEffect(() => {
    if (id) getIndividualInsurancePortal()
    if (institutes?.length === 0) getInstitute()
    if (programs?.length === 0) getPrograms()
    if (sessions?.length === 0) getSession()
    if (sendingInstitutions?.length === 0) getSendingInstitution()
    if (homeColleges?.length === 0) getHomeCollege()
    if (years?.length === 0) getYears()
  }, [])

  const getIndividualInsurancePortal = async () => {
    setLoading(true)
    await http
      .fetch({ path: `setting/insurance-portal/${id}` })
      .catch(() => {
        toast('Internal Server Error.', 'error')
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          reset({
            title: response?.data?.insurance?.title,
            content: response?.data?.insurance?.content,
            institute: response?.data?.insurance?.institute,
            session: response?.data?.insurance?.session,
            program: response?.data?.insurance?.program,
            year: response?.data?.insurance?.year,
            sendingInstitution: response?.data?.insurance?.sendingInstitution ?? [],
            homeCollege: response?.data?.insurance?.homeCollege ?? [],
            applicationStatus: response?.data?.insurance?.applicationStatus ?? []
          })
          setSelectedInstitute(response?.data?.insurance?.institute)
          setSelectedSession(response?.data?.insurance?.session)
          setSelectedProgram(response?.data?.insurance?.program)
          setSelectedYear(response?.data?.insurance?.year)
          setSelectedSendingInstitution(response?.data?.insurance?.sendingInstitution)
          setSelectedHomeCollege(response?.data?.insurance?.homeCollege)
          setSelectedStudent(response?.data?.insurance?.student)
          setSelectedApplicationStatus(response?.data?.insurance?.applicationStatus ?? [])
        }
      })
  }

  const getStudent = async () => {
    await setLoading(true)
    const searchData = {
      institute: getValues('institute').filter((value) => value !== 0),
      session: getValues('session').filter((value) => value !== 0),
      program: getValues('program').filter((value) => value !== 0),
      sendingInstitution: getValues('sendingInstitution'),
      homeCollege: getValues('homeCollege').filter((value) => value !== 0),
      year: getValues('year').filter((value) => value !== 0),
      applicationStatus: getValues('applicationStatus').filter((value) => value)
    }

    await http
      .fetch({ method: `post`, path: 'setting/dashboardLinksIconStudents', data: searchData })
      .catch(() => {
        toast('Internal server error.', 'error')
        setLoading(false)
      })
      .then((response) => {
        setLoading(false)
        if (response && response.status === 200) {
          setData((prevData) => ({
            ...prevData,
            students: response.data.students
          }))
        }
      })
    await setLoading(false)
  }

  useEffect(() => {
    if (
      getValues('institute').length > 0 ||
      getValues('session').length > 0 ||
      getValues('program').length > 0 ||
      getValues('sendingInstitution').length > 0 ||
      getValues('homeCollege').length > 0 ||
      getValues('year').length > 0 ||
      getValues('applicationStatus').length > 0
    ) {
      getStudent()
    } else {
      setData({})
    }
  }, [
    getValues('institute'),
    getValues('session'),
    getValues('program'),
    getValues('year'),
    getValues('sendingInstitution'),
    getValues('homeCollege'),
    getValues('applicationStatus')
  ])

  const handleSave = async (info: FormDataFields) => {
    await setLoading(true)
    const newData = {
      title: info?.title,
      content: info?.content,
      institute: info?.institute,
      session: info?.session,
      program: info?.program,
      sendingInstitution: info?.sendingInstitution,
      homeCollege: info?.homeCollege,
      student: selectedStudentId,
      year: info?.year,
      applicationStatus: info?.applicationStatus
    }
    const method = id ? 'put' : 'post'
    const path = id ? `setting/insurance-portal/${id}` : `setting/insurance-portal`
    await http
      .fetch({ method: method, path: path, data: removeBlankPairs(newData) })
      .catch((error) => {
        setError({
          message: error?.response?.data?.message || error.message,
          ...(error?.response?.data?.errors || [])
        })
        setLoading(false)
      })
      .then((response: any) => {
        setLoading(false)
        if (response && response.status === 200) {
          navigate('/admin/setting/insurance-portal')
          toast(response?.data?.message || 'Successfully added data', 'success')
        }
      })
    await setLoading(false)
  }
  const [selectAll, setSelectAll] = useState<boolean>(false)

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked)
    setSelectedStudent(event.target.checked ? data?.students?.map((student) => student.id) || [] : [])
  }

  const handleCheckSingle = (studentId: number) => {
    setSelectedStudent((prevSelected) => {
      if (prevSelected.includes(studentId)) {
        return prevSelected.filter((id) => id !== studentId)
      } else {
        return [...prevSelected, studentId]
      }
    })
  }

  useEffect(() => {
    if (data?.students?.length === selectedStudentId.length) {
      setSelectAll(true)
    } else {
      setSelectAll(false)
    }
  }, [selectedStudentId, data?.students])

  const filterOptions = createFilterOptions({
    limit: 100,
    trim: true
  })
  // const apiKey = process.env.REACT_APP_TINYMCE_API_KEY ?? ''
  return (
    <div className='flex w-full min-h-screen md:flex-wrap md:min-h-full lg:flex-col'>
      {loading && <Loading />}
      <AdminSetting defaultOpen='dashboard_icons' />
      <div className='m-10 px-4 w-full lg:m-0 lg:px-4 lg:py-7' data-cy='insurance-portal-add-edit-page'>
        <div className='flex justify-between items-center'>
          <h1 className='text-3xl font-bold' data-cy='insurance-portal'>
            Insurance Portal
          </h1>
          <Link to='/admin/setting/insurance-portal'>
            <button className='btn-primary flex items-center gap-x-2' data-cy='go-back'>
              <IoIosArrowBack />
              Back
            </button>
          </Link>
        </div>
        <form
          className='p-7 md:p-4 rounded-md border border-slate-200 shadow-2xl shadow-primary/20	 mt-10'
          onSubmit={handleSubmit(handleSave)}
        >
          <div>
            <div className='mb-6 md:mb-0'>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-first-name'>
                Title
              </label>
              <input
                className='form-input'
                type='text'
                {...register('title', {
                  required: false
                })}
                name='title'
                data-cy='title'
                placeholder='Enter title'
              />
              {errors?.title?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Title is required
                </p>
              )}
              {error.title && (
                <p className='text-red-500 text-xs italic' data-cy='error'>
                  {error.title[0]}
                </p>
              )}
            </div>
            <div className=''>
              <label className='block uppercase tracking-wide text-gray-700 text-base font-semibold mb-2' htmlFor='grid-last-name'>
                Content
              </label>
              <input type='hidden' {...register('content', { required: true })} />
              <CKEditor
                editor={ClassicEditor}
                data={getValues('content')}
                onReady={(editor) => {
                  editorRef.current = editor
                }}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setValue('content', data)
                }}
                config={CKEditorConfig}
                data-cy='editor'
              />
              {/* // <Editor
                //   initialValue={getValues('content')}
                //   onInit={(evt, editor) => (editorRef.current = editor)}
                //   apiKey={apiKey}
                //   disabled={hasView}
                //   onEditorChange={(content) => {
                //     setValue('content', content)
                //   }}
                //   {...register('content', {
                //     required: true
                //   })}
                //   init={{
                //     height: 500,
                //     resize: 'both',
                //     plugins: [...TinyMcePlugins],
                //     toolbar: [...TinyMceToolbar],
                //     ...TinyMceCustomizationOptions,
                //     save_onsavecallback: (instance: any) => {
                //       setValue('content', instance?.getContent())
                //       handleSubmit(handleSave)()
                //     }
                //   }}
                //   data-cy='editor'
                // /> */}
              {errors?.content?.type == 'required' && (
                <p className='text-left text-red-500 text-sm' data-cy='error'>
                  Content is required
                </p>
              )}
              {error.content && (
                <p className='text-red-500 text-xs italic' data-cy='error'>
                  {error.content[0]}
                </p>
              )}
            </div>
            <div className='flex justify-between items-center md:mt-3 mt-5'>
              <div className='flex gap-3 w-full md:flex-wrap md:[&>*]:w-full md:[&>*]:!mx-0 flex-wrap items-center'>
                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                  <InputLabel id='insurance-portal-institutes-label'>Institutes</InputLabel>
                  <Select
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    data-cy='institute'
                    labelId='insurance-portal-institutes-label'
                    id='insurance-portal-institutes'
                    multiple
                    autoWidth
                    value={getValues('institute')}
                    {...register('institute')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('institute', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                      setSelectedInstitute(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    }}
                    input={<OutlinedInput label='Institutes' />}
                    renderValue={(selected) => {
                      const selectedNames = institutes
                        ?.filter((institute) => selected.includes(institute.id))
                        .map((institute) => institute.name)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    MenuProps={MenuProps}
                  >
                    {institutes?.map((institute) => {
                      return (
                        <MenuItem key={institute?.id} value={institute?.id}>
                          <Checkbox
                            checked={getValues('institute')?.indexOf(institute?.id) > -1 && selected_institute.includes(institute?.id)}
                          />
                          <ListItemText primary={institute?.name} />
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                  <InputLabel id='insurance-portal-sessions-label'>Sessions</InputLabel>
                  <Select
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    data-cy='sessions'
                    labelId='insurance-portal-sessions-label'
                    id='insurance-portal-sessions'
                    multiple
                    autoWidth
                    value={getValues('session')}
                    {...register('session')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('session', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                      setSelectedSession(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    }}
                    input={<OutlinedInput label='Sessions' />}
                    renderValue={(selected) => {
                      const selectedNames = sessions?.filter((session) => selected.includes(session.id)).map((session) => session.name)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    MenuProps={MenuProps}
                  >
                    {sessions?.map((session) => (
                      <MenuItem key={session?.id} value={session?.id}>
                        <Checkbox checked={getValues('session').indexOf(session?.id) > -1 && selected_session.includes(session?.id)} />
                        <ListItemText primary={session?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                  <InputLabel id='insurance-portal-years-label'>Years</InputLabel>
                  <Select
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    data-cy='years'
                    labelId='insurance-portal-years-label'
                    id='insurance-portal-years'
                    multiple
                    autoWidth
                    value={getValues('year')}
                    {...register('year')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('year', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                      setSelectedYear(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    }}
                    input={<OutlinedInput label='years' />}
                    renderValue={(selected) => {
                      const selectedNames = years?.filter((year) => selected.includes(year.id)).map((year) => year.name)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    MenuProps={MenuProps}
                  >
                    {years?.map((year) => (
                      <MenuItem key={year?.id} value={year?.id}>
                        <Checkbox checked={getValues('year').indexOf(year?.id) > -1 && selected_year.includes(year?.id)} />
                        <ListItemText primary={year?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                  <InputLabel id='insurance-portal-programs-label'>Programs</InputLabel>
                  <Select
                    labelId='insurance-portal-programs-label'
                    id='insurance-portal-programs'
                    data-cy='programs'
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    multiple
                    autoWidth
                    value={getValues('program')}
                    {...register('program')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('program', value?.length > 0 ? value.toString().split(',').map(Number) : [])
                      setSelectedProgram(value?.length > 0 ? value.toString().split(',').map(Number) : [])
                    }}
                    input={<OutlinedInput label='Programs' />}
                    renderValue={(selected) => {
                      const selectedNames = programs?.filter((program) => selected.includes(program.id)).map((program) => program.name)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    placeholder='Select Session'
                    MenuProps={MenuProps}
                  >
                    {programs?.map((program) => (
                      <MenuItem key={program?.id} value={program?.id}>
                        <Checkbox checked={getValues('program').indexOf(program?.id) > -1 && selected_program.includes(program?.id)} />
                        <ListItemText primary={program?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '160px' }}>
                  <InputLabel id='insurance-portal-sending-institution-label'>Sending Institution</InputLabel>
                  <Select
                    labelId='insurance-portal-sending-institution-label'
                    id='insurance-portal-sending-institution'
                    data-cy='sending-institution'
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    multiple
                    autoWidth
                    value={getValues('sendingInstitution')}
                    {...register('sendingInstitution')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('sendingInstitution', Array.isArray(value) ? value : [])
                      setSelectedSendingInstitution(Array.isArray(value) ? value : [])
                    }}
                    input={<OutlinedInput label='Sending Institution' />}
                    renderValue={(selected) => {
                      const selectedNames = sendingInstitutions
                        ?.filter((sendingInstitution) => selected.includes(sendingInstitution.value))
                        .map((sendingInstitution) => sendingInstitution.text)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    placeholder='Select Sending Institution'
                    MenuProps={MenuProps}
                  >
                    {sendingInstitutions?.map((sendingInstitution) => (
                      <MenuItem key={sendingInstitution?.value} value={sendingInstitution?.value}>
                        <Checkbox
                          checked={
                            getValues('sendingInstitution').indexOf(sendingInstitution?.value) > -1 &&
                            selected_sending_institution.includes(sendingInstitution?.value)
                          }
                        />
                        <ListItemText primary={sendingInstitution?.text} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '180px' }}>
                  <InputLabel id='application-status-input-label'>Application Status</InputLabel>
                  <Select
                    labelId='application-status-input-label'
                    id='application-status-input'
                    data-cy='application-status'
                    className='min-w-44 max-w-44 md:min-w-full md:max-w-full'
                    multiple
                    autoWidth
                    value={getValues('applicationStatus')}
                    {...register('applicationStatus')}
                    onChange={(event) => {
                      const { value } = event.target
                      setValue('applicationStatus', Array.isArray(value) ? value : [])
                      setSelectedApplicationStatus(Array.isArray(value) ? value : [])
                    }}
                    input={<OutlinedInput label='Application Status' />}
                    renderValue={(selected) => {
                      const selectedNames = applicationStatusFilters
                        ?.filter((applicationStatusFilter) => selected.includes(applicationStatusFilter.value))
                        .map((applicationStatusFilter) => applicationStatusFilter.label)
                      return selectedNames ? selectedNames.join(', ') : ''
                    }}
                    placeholder='Select Application Status'
                    MenuProps={MenuProps}
                  >
                    {applicationStatusFilters?.map((applicationStatusFilter) => (
                      <MenuItem key={applicationStatusFilter?.value} value={applicationStatusFilter?.value}>
                        <Checkbox
                          checked={
                            getValues('applicationStatus')?.indexOf(applicationStatusFilter?.value) > -1 &&
                            selected_application_status.includes(applicationStatusFilter?.value)
                          }
                        />
                        <ListItemText primary={applicationStatusFilter?.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className='flex gap-3 items-center max-w-[53%] min-w-[41%] md:max-w-full md:min-w-full ml-2'>
                  <Autocomplete
                    className='dashboard-icon-home-college w-full'
                    data-cy='home-college'
                    multiple
                    componentsProps={{ popper: { id: 'insurance-portal-home-college-popup' } }}
                    options={homeColleges}
                    {...register('homeCollege')}
                    getOptionLabel={(option: any) => option.name}
                    disableCloseOnSelect={true}
                    value={homeColleges.filter((option) => selected_home_college && selected_home_college.includes(option.id))}
                    onChange={(event, selectedOptions) => {
                      const selectedIds = Array.isArray(selectedOptions) ? selectedOptions.map((option: any) => option.id) : []
                      setValue('homeCollege', selectedIds)
                      setSelectedHomeCollege(selectedIds)
                    }}
                    filterOptions={filterOptions}
                    renderInput={(params) => <TextField {...params} label='Home University' />}
                  />
                  <HomeCollegeHelp />
                </div>
                <div className='flex justify-center ml-4'>
                  <button type='submit' className='btn-primary flex items-center gap-x-3' data-cy='save-btn'>
                    Save <FaSave />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {error.message && (
            <div className='text-left text-red-500 text-sm' data-cy='error'>
              <p>{error?.message}</p>
            </div>
          )}
        </form>
        <div className='overflow-auto' id='student-data'>
          <table className='w-full lg:w-max'>
            <thead>
              <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                <th className='cursor-pointer'>
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: '#ffffff' } }}
                    checked={selectAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>Session</th>
                <th>Academic Year</th>
                <th>Academic Program</th>
                <th>Host Institute</th>
                <th>Sending Institution</th>
                <th>Home University</th>
              </tr>
            </thead>
            <tbody>
              {data?.students?.length === 0 || !data?.students ? (
                <>
                  <tr>
                    <td colSpan={7}>No Student Found</td>
                  </tr>
                </>
              ) : (
                <>
                  {data?.students?.map((student) => (
                    <tr key={student.id}>
                      <td className='cursor-pointer text-center'>
                        <Checkbox
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                          checked={selectedStudentId.includes(student.id)}
                          onChange={() => handleCheckSingle(student.id)}
                        />
                      </td>
                      <td>{student.firstName}</td>
                      <td>{student.lastName}</td>
                      <td>{student?.session?.name ?? '-'}</td>
                      <td>{student?.year?.name ?? '-'}</td>
                      <td>{student?.program?.name ?? '-'}</td>
                      <td>{student?.institute?.name ?? '-'}</td>
                      <td>{student.sendingInstitution ?? '-'}</td>
                      <td>{student?.homeCollege?.name ?? '-'}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
