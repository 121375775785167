import { NavLink, useLocation } from 'react-router-dom'
import { FaAngleDown, FaAngleUp, FaArrowRight } from 'react-icons/fa'
import DashboardIcon from 'Components/DashboardIcon'
import { isSuperAdmin, isAdmin } from 'CommonJS/roleHelper'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'Context/UserContext'
import { FaArrowUpRightFromSquare } from 'react-icons/fa6'
import { OpenAccordionType } from './type'

export default function AdminSetting({ defaultOpen }: { defaultOpen?: OpenAccordionType }) {
  const location = useLocation()
  const { data } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState<OpenAccordionType | undefined>(defaultOpen)

  const linkGroups: Record<OpenAccordionType, string[]> = {
    dashboard_icons: [
      '/admin/setting/insurance-portal',
      '/admin/setting/pre-departure-guide',
      '/admin/setting/pre-departure-course',
      '/admin/setting/smartphone-app',
      '/admin/setting/course-schedule'
    ],
    settings: [
      '/admin/setting/basic',
      '/admin/setting/mail',
      '/admin/setting/form',
      '/admin/setting/template',
      '/admin/setting/emergency-banner',
      '/admin/setting/roles-permissions',
      '/admin/setting/field-filter',
      '/admin/student/global-view'
    ],
    student_info_dashboard: ['/admin/setting/activities-attended', '/admin/setting/tag']
  }

  useEffect(() => {
    for (const [key, links] of Object.entries(linkGroups)) {
      if (links.includes(location.pathname)) {
        isOpen !== key && setIsOpen(key as OpenAccordionType)
        return
      }
    }
  }, [location.pathname])

  const toggleSettings = (toggleBlock: OpenAccordionType): void => {
    setIsOpen((prev) => (prev === toggleBlock ? undefined : toggleBlock))
  }

  return (
    <>
      <div className='w-60 min-w-60 py-4 bg-slate-100 shadow-lg bottom-0 lg:w-full lg:min-w-full lg:pb-0 sticky top-[77px] max-h-[calc(100vh-77px)] md:static md:max-h-full overflow-y-auto'>
        <ul className='text-md [&_a]:p-4 [&_a:hover]:!bg-primary [&_li:hover]:!text-white [&_a.active>svg]:block [&_a.active]:bg-primary [&_a.active]:!text-white [&_a.active]:shadow-sm'>
          <DashboardIcon isOpen={isOpen} toggleSettings={toggleSettings} />
          <h3
            className='font-semibold p-3 pl-5 cursor-pointer flex items-center justify-between'
            onClick={() => toggleSettings('settings')}
          >
            SETTINGS {isOpen ? <FaAngleUp /> : <FaAngleDown />}
          </h3>
          {isSuperAdmin(data?.roles) && isOpen == 'settings' ? (
            <>
              <li className='cursor-pointer [&>a]:!pl-9'>
                <NavLink to='/admin/setting/basic' className='flex justify-between items-center'>
                  Basic Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='cursor-pointer [&>a]:!pl-9'>
                <NavLink to='/admin/setting/mail' className='flex justify-between items-center'>
                  Mail Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink to={'/admin/setting/form'} className='flex justify-between items-center'>
                  Form Settings
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/template'}
                  className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/template') ? 'active' : ''}`}
                >
                  Email Templates
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/emergency-banner'}
                  className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/emergency-banner') ? 'active' : ''}`}
                >
                  Banner Notice
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/roles-permissions'}
                  className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/roles-permissions') ? 'active' : ''}`}
                >
                  Roles Permissions
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink
                  to={'/admin/setting/field-filter'}
                  className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/field-filter') ? 'active' : ''}`}
                >
                  Students Field Filter
                  <FaArrowRight className='hidden' />
                </NavLink>
              </li>
              <li className='[&>a]:!pl-9'>
                <NavLink to={'/admin/student/global-view'} className='flex justify-between items-center'>
                  Manage Global View
                  <FaArrowUpRightFromSquare />
                </NavLink>
              </li>
            </>
          ) : (
            <>
              {isAdmin(data?.roles) && isOpen == 'settings' && (
                <>
                  <li className='[&>a]:!pl-9'>
                    <NavLink
                      to={'/admin/setting/template'}
                      className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/template') ? 'active' : ''}`}
                    >
                      Email Templates
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                  <li className='[&>a]:!pl-9'>
                    <NavLink
                      to={'/admin/setting/emergency-banner'}
                      className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/emergency-banner') ? 'active' : ''}`}
                    >
                      Banner Notice
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
          {isSuperAdmin(data?.roles) && (
            <>
              <h3
                className='font-semibold p-3 pl-5 cursor-pointer flex items-center justify-between'
                onClick={() => toggleSettings('student_info_dashboard')}
              >
                STUDENT INFO {isOpen ? <FaAngleUp /> : <FaAngleDown />}
              </h3>
              {isOpen == 'student_info_dashboard' && (
                <>
                  <li>
                    <NavLink
                      to={'/admin/setting/activities-attended'}
                      className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/activities-attended') ? 'active' : ''}`}
                    >
                      Activities Attended
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={'/admin/setting/tag'}
                      className={`flex justify-between items-center ${location.pathname.startsWith('/admin/setting/tag') ? 'active' : ''}`}
                    >
                      Tags
                      <FaArrowRight className='hidden' />
                    </NavLink>
                  </li>
                </>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  )
}
