import http from 'CommonJS/http'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { ActionType, FormSubStep, SubStepInfo } from './type'
import Loading from 'Components/Loading'
import { useForm } from 'react-hook-form'
import { subStepContext } from './context'
import { useLocation, useParams } from 'react-router-dom'
import { UserContext } from 'Context/UserContext'
import { toast } from 'Components/Toast/toast'
import Cookie from 'js-cookie'
import { isAdmin } from 'CommonJS/roleHelper'
import { linkify } from 'CommonJS/common'
import FormItem from './FormItem'
import { FormSubStepSlugEnum } from 'CommonJS/FormSubStepSlugEnum'
// import { Editor } from '@tinymce/tinymce-react'
// import { Editor as TinyMCEEditor } from 'tinymce'
// import { TinyMcePlugins, TinyMceToolbar, TinyMceCustomizationOptions } from 'Components/TinyEditor/pluginAndTollbar'
import { DisableDateFields } from 'CommonJS/disableDateFields'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ClassicEditor } from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import { Editor as CKEditorType } from '@ckeditor/ckeditor5-core'
import { CKEditorConfig } from 'Components/CKEditor/pluginAndTollbar'
import { checkItemRenderLogic } from 'CommonJS/checkRenderLogic'

interface Props {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  formSubStep: FormSubStep | null | any
  formStepInformation?: SubStepInfo | null
  disabled?: boolean
  wantGravityForm?: boolean
  disabledCallback?: (notify?: boolean) => boolean
  saveInfoCallback?: (status: { step1Status: string; step2Status: string; step3Status: string; step4Status: string }) => void
  isViewOnly?: boolean
  init: any
  getSubStepFormMeta: () => Promise<void>
  isDownloadPDF?: boolean
}

function StepDetails({
  setOpenModal,
  formSubStep,
  formStepInformation,
  disabled = false,
  wantGravityForm = true,
  disabledCallback = () => true,
  saveInfoCallback,
  isViewOnly,
  init,
  getSubStepFormMeta,
  isDownloadPDF = true
}: Props) {
  const [loading, setLoading] = useState<boolean>(true)
  const [formLoading, setFormLoading] = useState<boolean>(false)
  const { state, dispatch } = useContext(subStepContext)
  const { currentPage, gravityForm, subFormFields, subStepForm, stepFormMeta } = state
  const match = useLocation()
  const { id } = useParams()
  const { data } = useContext(UserContext)

  const { handleSubmit, register, watch, control, setValue, reset, getValues, setError } = useForm({
    defaultValues: stepFormMeta
  })

  let studentId: any
  if (match.pathname.includes('student-dashboard')) {
    studentId = id
  } else {
    studentId = data?.student?.id
  }
  const editorRef = useRef<CKEditorType | null>(null)

  // const apiKey = process.env.REACT_APP_TINYMCE_API_KEY ?? ''

  useEffect(() => {
    getFormSubSteps()
  }, [])

  function handleClose() {
    dispatch({ type: ActionType.SET_SUBSTEP_FORM, payload: null })
    setOpenModal(false)
  }

  function closeOnEscape(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === 'Escape' && (isViewOnly || confirm('Are you sure, you want to close the form.'))) {
      handleClose()
    }
  }

  async function getFormSubSteps() {
    await setLoading(true)
    await http
      .fetch({ path: `student/formSubSteps/${formSubStep?.id}` })
      .then(async (res) => {
        if (res.data.success && res.status === 200) {
          const tempMeta = {}
          const fields: Array<any> = res.data?.data?.parsedGravityForm?.fields
          for (let i = 0; i < fields?.length; i++) {
            const field = fields[i]
            let value = ''
            if (field?.type === 'signature' || field?.type === 'fileupload') {
              value = stepFormMeta[field?.adminLabel]
              if (value) {
                Object.assign(tempMeta, { [field?.id?.toString()]: value })
              }
            } else if (field?.type === 'address' || field?.type === 'name') {
              for (let j = 0; j < field?.inputs?.length; j++) {
                const input = field?.inputs[j]
                value = stepFormMeta[input?.name?.toLowerCase()]
                if (value) {
                  Object.assign(tempMeta, { [input?.id?.toString()]: value })
                }
              }
            } else {
              value = stepFormMeta[field?.inputName?.toLowerCase()]
              if (value) {
                Object.assign(tempMeta, { [field?.id?.toString()]: value })
              }
            }
          }
          wantGravityForm && (await reset(tempMeta))
          await dispatch({ type: ActionType.SET_SUBSTEP_FORM, payload: res.data?.data })

          // set default value
          if (res?.data?.data?.parsedGravityForm && wantGravityForm) {
            const fields: any[] = await res?.data?.data?.parsedGravityForm?.fields
            for (let i = 0; i < fields?.length; i++) {
              const field = await fields[i]
              if (field?.inputName) {
                if (
                  stepFormMeta[fields[i]?.inputName?.toLowerCase()] === undefined ||
                  stepFormMeta[fields[i]?.inputName?.toLowerCase()] === null
                ) {
                  if (fields[i]?.type === 'checkbox') {
                    const value: string[] = []
                    await field?.choices.forEach((element: { isSelected: any; value: string }) => {
                      if (element?.isSelected) {
                        value.push(element?.value)
                      }
                    })
                    if (value?.length > 0) {
                      await reset({ ...tempMeta, [field?.id?.toString()]: value })
                    }
                  }
                }
              }
            }
          }
          await setTimeout(() => {
            setLoading(false)
          }, 300)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  async function submitForm(data: any, event: any) {
    const meta = {}
    for (let i = 0; i < subFormFields?.length; i++) {
      const field = subFormFields[i]
      let value: any = ''
      if (field?.type === 'signature' || field?.type === 'fileupload') {
        value = data[field?.id]
        if (value) {
          Object.assign(meta, { [field?.adminLabel?.toLowerCase()]: value })
        }
      } else if (field?.type === 'address' || field?.type === 'name') {
        const inputs = await field?.inputs?.filter((data: any) => !data?.isHidden)
        for (let j = 0; j < inputs?.length; j++) {
          const input = inputs[j]
          value = data[field?.id?.toString()]
          if (value) {
            value = await value?.filter((data: any) => data)
          }
          if (value) {
            Object.assign(meta, { [input?.name?.toLowerCase()]: value[j] })
          }
        }
      } else if (field?.type === 'date') {
        value = data[field?.id]
        if (value && DisableDateFields?.includes(field?.inputName)) {
          Object.assign(meta, { [field?.inputName.toLowerCase()]: new Date() })
        } else Object.assign(meta, { [field?.inputName.toLowerCase()]: value })
      } else {
        value = data[field?.id]
        if (value) {
          Object.assign(meta, { [field?.inputName.toLowerCase()]: value })
        }
      }
    }
    if (disabled || !disabledCallback(true)) {
      event?.preventDefault()
      return
    }
    try {
      await setFormLoading(true)
      const hubspotToken = Cookie.get('hubspotutk')
      const response = await http.fetch({
        method: 'post',
        path: `saveStepFrom/${studentId}/${subStepForm.id}`,
        data: {
          ...meta,
          hubspotToken,
          isFinalSubmit: !(gravityForm?.pagination && currentPage !== gravityForm?.pagination?.pages?.length)
        }
      })
      if (response.data.success) {
        if (gravityForm?.pagination && currentPage !== gravityForm?.pagination?.pages?.length) {
          onNext()
          await getSubStepFormMeta()
        } else {
          await reset()
          await setOpenModal(false)
          await init()
        }
        await toast(response.data.message, 'success')
        saveInfoCallback?.(response.data.stepStatus)
      }
      await setFormLoading(false)
    } catch (error: any) {
      toast(error?.response?.data?.error ?? 'something went wrong please try again', 'error', { autoHideDuration: 5000 })
      setFormLoading(false)
    }
  }

  async function submitLinkForm(data: any, event: any) {
    if (disabled || !disabledCallback(true)) {
      event?.preventDefault()
      return
    }
    try {
      await setFormLoading(true)
      const hubspotToken = Cookie.get('hubspotutk')
      const response = await http.fetch({
        method: 'post',
        path: `saveStepFrom/${studentId}/${subStepForm.id}`,
        data: {
          ...data,
          hubspotToken,
          isCourseScheduleLinkForm: formSubStep?.slug === FormSubStepSlugEnum.COURSE_SCHEDULE,
          isFinalSubmit: true
        }
      })
      if (response.data.success) {
        if (gravityForm?.pagination && currentPage !== gravityForm?.pagination?.pages?.length) {
          onNext()
        } else {
          await reset()
          await setOpenModal(false)
          await init()
        }
        await toast(response.data.message, 'success')
        saveInfoCallback?.(response.data.stepStatus)
      }
      await setFormLoading(false)
    } catch (error) {
      toast('something went wrong please try again', 'error')
      setFormLoading(false)
    }
  }
  async function onNext() {
    dispatch({ type: ActionType.NEXT_PAGE })
  }

  async function onPrevious() {
    dispatch({ type: ActionType.PREVIOUS_PAGE })
  }

  async function handleDownloadPdf() {
    if (!formStepInformation?.isSubmitted) {
      toast('Please complete the form first to download pdf.', 'warning')
      return
    }

    setFormLoading(true)
    const ids: number[] = []
    subFormFields.map((item: any) => {
      const { isRenderConditionalComponent } = checkItemRenderLogic(item, subFormFields, subStepForm.slug, data, isViewOnly, watch)
      if (isRenderConditionalComponent) {
        ids.push(item.id as number)
      }
    })

    await http
      .fetch({ method: 'post', path: `/student/${studentId}/downloadForm/${subStepForm.id}`, data: { fieldIds: ids } })
      .catch((error) => {
        setFormLoading(false)
        toast(error?.response?.data?.message, 'error')
      })
      .then((response) => {
        setFormLoading(false)
        if (response && response.status === 200 && response.data?.success) {
          toast(response?.data?.message, 'success')
        } else {
          toast(response?.data?.message, 'error')
        }
      })
  }

  if (loading) return <Loading />

  if (subStepForm?.isGravityForm && wantGravityForm) {
    return (
      <div
        className='w-full h-full fixed justify-center items-center bg-black/50  inset-x-0 inset-y-0 border z-[190] p-10 md:p-4'
        onKeyDown={closeOnEscape}
        tabIndex={1}
      >
        {formLoading && <Loading />}
        <div className='bg-white w-full h-full rounded-md p-5 relative overflow-hidden md:p-3 view-student'>
          {/* header start */}
          <div className='flex justify-between items-center'>
            <h1 className=' font-semibold text-2xl'>{gravityForm?.title}</h1>
            <div className='flex items-center gap-5'>
              {isDownloadPDF && (
                <button
                  className={`btn-secondary ${!formStepInformation?.isSubmitted ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={handleDownloadPdf}
                >
                  Download PDF
                </button>
              )}
              <MdClose className='cursor-pointer ' size={28} onClick={handleClose} />
            </div>
          </div>

          <form className='h-full' onSubmit={handleSubmit(submitForm)}>
            {/* field listing */}
            <div className='mt-2 w-full h-[calc(100%-99px)] p-2 overflow-auto [&_a]:underline [&_a]:underline-offset-4 md:pl-0 md:h-[calc(100%-175px)]'>
              {subFormFields?.map((item: any, index: number) => {
                const { isRenderConditionalComponent, isFieldViewOnly } = checkItemRenderLogic(
                  item,
                  subFormFields,
                  subStepForm.slug,
                  data,
                  isViewOnly,
                  watch
                )

                return (
                  <FormItem
                    item={item}
                    key={index}
                    index={index}
                    control={control}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    isViewOnly={isViewOnly}
                    setError={setError}
                    studentId={studentId}
                    handleSubmit={handleSubmit}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    setOpenModal={setOpenModal}
                    isRenderConditionalComponent={isRenderConditionalComponent}
                    isFieldViewOnly={isFieldViewOnly}
                  />
                )
              })}
            </div>

            {/* submit button */}
            {gravityForm?.pagination === null && (
              <div className='flex justify-center bg-white border-t py-4 absolute inset-x-0 bottom-0 md:py-2'>
                {isViewOnly ? (
                  <button className='btn-secondary' type='reset' onClick={() => setOpenModal(false)}>
                    Close
                  </button>
                ) : (
                  <button className='btn-secondary' type='submit'>
                    {gravityForm?.button?.text ? gravityForm?.button?.text : 'Submit'}
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    )
  }

  return (
    <div
      className='w-full h-full fixed justify-center items-center bg-black/50  inset-x-0 inset-y-0 border z-[190] p-10 flex md:p-5'
      onKeyDown={closeOnEscape}
      tabIndex={0}
    >
      {subStepForm && (
        <div
          className={`bg-white rounded-md p-5 relative overflow-hidden ${isViewOnly || data?.roles.includes('student') ? 'w-full h-full' : 'w-[60vw] h-[fit-content]'} max-h-[96vh] overflow-y-auto`}
        >
          {formLoading && <Loading />}
          <form className='h-full' onSubmit={handleSubmit(submitLinkForm)}>
            <label className='uppercase tracking-wide text-gray-500 text-sm mb-2' htmlFor='grid-first-name'>
              {subStepForm?.label}
            </label>
            {isViewOnly || data?.roles.includes('student') ? (
              <>
                <iframe
                  className={`w-full h-[calc(100%-75px)] border border-slate-200 md:h-[calc(100vh-160px)]`}
                  sandbox='allow-scripts allow-popups allow-downloads allow-modals' // to allow link tag network request. otherwise it will be canceled.
                  srcDoc={
                    getValues(subStepForm?.key)
                      ? linkify(
                          `<html> <head><link rel="stylesheet" href="/css/ckeditor5-content.css" /></head> <body> <div class="ck-content"> ${getValues(subStepForm?.key)} </div> </body> </html>`,
                          { target: '_blank' }
                        ) + "<style> body{font-family:'Helvetica Neue',sans-serif;}</style>"
                      : '<b>Coming Soon</b>'
                  }
                ></iframe>
              </>
            ) : (
              <>
                <input type='hidden' {...register(subStepForm?.key)} />
                <CKEditor
                  editor={ClassicEditor}
                  data={getValues(subStepForm?.key)}
                  onReady={(editor) => {
                    editorRef.current = editor
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setValue(subStepForm?.key, data)
                  }}
                  config={CKEditorConfig}
                />
              </>
              // <Editor
              //   initialValue={getValues(subStepForm?.key)}
              //   onInit={(evt, editor) => (editorRef.current = editor)}
              //   apiKey={apiKey}
              //   onEditorChange={(content) => {
              //     setValue(subStepForm?.key, content)
              //   }}
              //   {...register(subStepForm?.key)}
              //   init={{
              //     height: 500,
              //     resize: 'both',
              //     plugins: [...TinyMcePlugins],
              //     toolbar: [...TinyMceToolbar],
              //     ...TinyMceCustomizationOptions
              //   }}
              // />
            )}
            <div className='flex justify-center items-center gap-6 mt-5'>
              {isViewOnly ||
                (isAdmin(data?.roles) && (
                  <button className={`btn-secondary ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} type='submit'>
                    {gravityForm?.button?.text ? gravityForm?.button?.text : 'Submit'}
                  </button>
                ))}
              <button className='btn-primary' onClick={() => setOpenModal(false)}>
                {isViewOnly || data?.roles.includes('student') ? 'Close' : 'Cancel'}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default StepDetails
