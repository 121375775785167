import { MdOutlineFilterAlt, MdSearch, MdViewColumn } from 'react-icons/md'
import Checkbox from '@mui/material/Checkbox'
import React, { ChangeEvent, lazy, Suspense, useContext, useEffect, useState } from 'react'
import Filter from './Filter'
import ColumnFilter from './ColumnFilter'
import { getFieldList, bulkAssignAdvisor, getAllStudent, getAllAdvisor } from './api'
import { studentContext } from './context'
import { ActionType, AdvisorType, Fields, SubStepIcons } from './types'
import { Select, MenuItem, InputLabel, FormControl, FormGroup, FormControlLabel, Modal, Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { FaSort, FaEye, FaSync } from 'react-icons/fa'
import ConfirmationModal from 'Components/ConfirmationModal'
import ReactJsPagination from 'react-js-pagination'
import Loading from 'Components/Loading'
import ImageSelector from 'Components/ImageSelector'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { PerPage } from 'Components/PerPage'
import { toast } from 'Components/Toast/toast'
import ConfirmationAlert from 'Components/ConfirmationAlert'
import http from 'CommonJS/http'
import noImagePlaceholder from 'assets/images/no-image-placeholder.png'
import FileUpload from 'Components/FileUpload'
import Export from './export'
import { UserContext } from 'Context/UserContext'
import { AppStatusStyle } from 'CommonJS/StatusEnum'
import { TbFilterEdit } from 'react-icons/tb'
import { isAdmin, isManagerAdvisor } from 'CommonJS/roleHelper'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { debounce, formatDate, isValidDate, uploadFile } from 'CommonJS/common'
import { FreezeTableColumns } from 'Components/FreezeTableColumns'
import EventBus from 'CommonJS/eventBus'
import View from './View'
import { userCan } from 'CommonJS/PermissionHelper'
import { PermissionEnum } from 'CommonJS/PermissionEnum'
import { Close, Download } from '@mui/icons-material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import styled from '@emotion/styled'
import { BulkActionsEnum } from './BulkActionsEnum'

const ViewByFilter = lazy(() => import('./ViewByFilter'))

declare global {
  interface Window {
    distance?: number
  }
}

export default function Student() {
  const [openFilter, setOPenFilter] = useState(false)
  const [openView, setOpenView] = useState(false)
  const [openColumnFilter, setOpenColumnFilter] = useState(false)
  const [openToggleFilter, setOpenToggleFilter] = useState(false)
  const { state, dispatch } = useContext(studentContext)
  const { students, fields, column } = state
  const [advisorList, setAdvisorList] = useState<AdvisorType[]>()
  const [selectedStudentId, setSelectedStudent] = useState<number[]>([])
  const [selectedAdvisor, setSelectedAdvisor] = useState<number | null>(null)
  const ids = Object.values(students.data).map((item) => item.id)
  const [sortOrder, setSortOrder] = useState({ columnId: null, ascending: true })
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [multipleValue, setMultipleValue] = useState<Record<string, any>>({})
  const [exclusionMultipleValue, setExclusionMultipleValue] = useState<Record<string, any>>({})
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [isConfirmAlertOpen, setConfirmAlertOpen] = useState<boolean>(false)
  const [action, setAction] = useState<BulkActionsEnum | null>()
  const [actionLabel, setActionLabel] = useState<string>('')
  const { data } = useContext(UserContext)
  const [autoScrollSpeed, setAutoScrollSpeed] = useState<number>(50)
  const [autoScrollSpeedLock, setAutoScrollSpeedLock] = useState<boolean>(true)
  const [inputSearch, setInputSearch] = useState<string>('')
  const [subStepStatusIcons, setSubStepStatusIcons] = useState<SubStepIcons>({})
  const [isFirstRender, setIsFirstRender] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [uploadInfoModal, setUploadInfoModal] = useState(false)
  const [studentFile, setStudentFile] = useState<any>('')
  const [shouldRender, setShouldRender] = useState(false)
  const { viewBy } = useParams()

  const IMG_BASE_URL = process.env.REACT_APP_S3_BASE_URL ?? ''
  const GF_SIGN_BASE_URL = '?page=gf_signature'

  async function getFields(fieldOnly: boolean = false) {
    const res = await getFieldList({ query: { fieldOnly } })
    if (res) {
      if (fieldOnly === true) {
        dispatch({ type: ActionType.SET_FIELDS, payload: res.fields })
      } else {
        setIsFirstRender(true)
        const sortedFields = res.fields.sort((a: Fields, b: Fields) => a.filterColumnOrder - b.filterColumnOrder)
        dispatch({ type: ActionType.SET_FIELDS, payload: sortedFields })
        dispatch({ type: ActionType.SET_FILTERS, payload: res.filters })
        dispatch({ type: ActionType.SET_EXCLUSION_FILTERS, payload: res.exclusionFilters })
        dispatch({ type: ActionType.SET_COLUMN, payload: res.fields.filter((item: any) => item.isDefault).map((item: any) => item.id) })
      }
    }
  }

  async function advisors() {
    const res = await getAllAdvisor()
    if (res) {
      setAdvisorList(res.advisors)
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const [hadSearchFilter, setHadSearchFilter] = useState<boolean>(false)
  useEffect(() => {
    const applicationStatus = searchParams.get('application_status')
    const sendingInstitution = searchParams.get('sendingInstitution')
    const instituteId = searchParams.get('instituteId')
    const programId = searchParams.get('programId')
    const sessionId = searchParams.get('sessionId')
    const yearId = searchParams.get('yearId')
    const homeCollegeId = searchParams.get('homeCollegeId')
    const depositDueDate = searchParams.get('due_date')
    const depositPaidDate = searchParams.get('deposit_paid_date')
    const balanceDueDate = searchParams.get('invoice_balance_due_date')
    const balancePaidDate = searchParams.get('invoice_balance_paid_date')
    const init = async () => {
      await setLoading(true)
      if (applicationStatus) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          application_status: [applicationStatus]
        }))
      }
      if (sendingInstitution) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          sending_institution: [sendingInstitution]
        }))
      }
      if (instituteId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          host_institute: [instituteId]
        }))
      }
      if (programId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          program: [programId]
        }))
      }
      if (sessionId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          session: [sessionId]
        }))
      }
      if (yearId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          year: [yearId]
        }))
      }
      if (homeCollegeId) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          home_college_university: [homeCollegeId]
        }))
      }
      if (depositDueDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          due_date: depositDueDate
        }))
      }
      if (depositPaidDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          deposit_paid_date: depositPaidDate
        }))
      }
      if (balanceDueDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_due_date: balanceDueDate
        }))
      }
      if (balancePaidDate) {
        setHadSearchFilter(true)
        await setMultipleValue((prevState) => ({
          ...prevState,
          invoice_balance_paid_date: balancePaidDate
        }))
      }
      await Promise.all([getFields(), advisors()])

      const storedSpeed = localStorage.getItem('autoScrollSpeed')
      window.distance = Number(autoScrollSpeed)
      if (storedSpeed) {
        setAutoScrollSpeed(Number(storedSpeed))
        window.distance = Number(storedSpeed)
      }
      setAutoScrollSpeedLock(false)
      document.addEventListener('keydown', addArrowScrollEvents)
    }
    init()

    setShouldRender(true)
    return () => {
      document.removeEventListener('keydown', addArrowScrollEvents)
    }
  }, [])

  useEffect(() => {
    handleFilter({
      ...multipleValue,
      onlyTrashed: viewBy
    })
  }, [viewBy])

  useEffect(() => {
    const getData = async () => {
      await setLoading(true)
      await Promise.all([getStudents()])
      await setLoading(false)
    }
    isFirstRender
    if (column?.length > 0 || isFirstRender) {
      getData()
    }
  }, [column, sortOrder, multipleValue])

  useEffect(() => {
    if (!hadSearchFilter) return

    let newApplicationStatus = multipleValue?.application_status?.toString()
    if (multipleValue?.application_status && 'value' in (multipleValue?.application_status as object)) {
      newApplicationStatus = (multipleValue?.application_status as any)?.map((s: { value: any }) => s.value)
    }

    setSearchParams((prevSearchParams) => {
      const updatedSearchParams = new URLSearchParams(prevSearchParams) // Create a new URLSearchParams object

      if (newApplicationStatus) {
        updatedSearchParams.set('application_status', newApplicationStatus)
      } else {
        updatedSearchParams.delete('application_status')
      }
      const fields = {
        host_institute: 'instituteId',
        session: 'sessionId',
        program: 'programId',
        year: 'yearId',
        home_college_university: 'homeCollegeId',
        sending_institution: 'sendingInstitution',
        due_date: 'dueDate',
        deposit_paid_date: 'depositPaidDate',
        invoice_balance_due_date: 'invoiceBalanceDueDate',
        invoice_balance_paid_date: 'invoiceBalancePaidDate'
      }

      Object.entries(fields).forEach(([field, paramName]) => {
        if (!multipleValue?.[field] || multipleValue?.[field]?.length === 0) {
          updatedSearchParams.delete(field)
          updatedSearchParams.delete(paramName)
        }
      })

      return updatedSearchParams.toString() // Return a string representation for URL update
    })
  }, [
    multipleValue?.application_status,
    multipleValue?.sending_institution,
    multipleValue?.host_institute,
    multipleValue?.session,
    multipleValue?.year,
    multipleValue?.home_college_university,
    multipleValue?.program,
    multipleValue?.due_date,
    multipleValue?.deposit_paid_date,
    multipleValue?.invoice_balance_due_date,
    multipleValue?.invoice_balance_paid_date
  ])

  const loadIcons = async () => {
    return http
      .fetch({ method: 'get', path: 'setting/icons' })
      .catch(() => {
        toast('Internal server error.', 'error')
      })
      .then((response) => {
        if (response && response.status === 200) {
          setSubStepStatusIcons(response.data.setting)
        }
      })
  }

  async function getStudents(pageNumber: number = 1, perPage?: number) {
    try {
      let items = itemsPerPage
      if (perPage || perPage == 0) {
        items = perPage
      }
      setCurrentPage(pageNumber)
      setLoading(true)
      const res = await getAllStudent({
        pageNumber,
        fields: column,
        filterData: multipleValue,
        exclusionFilterData: exclusionMultipleValue,
        sortOrder,
        perPage: items
      })
      if (res) {
        dispatch({ type: ActionType.SET_STUDENTS, payload: [] })
        dispatch({ type: ActionType.SET_STUDENTS, payload: res.students })

        if (Object.keys(subStepStatusIcons).length === 0) {
          const hasCustomFields = fields.some((field) => {
            return field.isCustom && (field.isDefault || column.includes(field.fieldName))
          })

          if (hasCustomFields) {
            await loadIcons()
          }
        }
        setLoading(false)
      } else {
        setLoading(false)
        toast('An error occurred', 'error')
      }
      EventBus.$dispatch('get:student-listing', '')
    } catch (error) {
      setLoading(false)
      toast('An error occurred', 'error')
    }
  }
  const handleFilter = (filter: any, exclusionFilter?: any) => {
    setMultipleValue(() => {
      const udpatedFilter = {
        onlyTrashed: multipleValue?.onlyTrashed,
        advisorId: multipleValue?.advisorId,
        searchParam: multipleValue?.searchParam,
        ...filter
      }
      return udpatedFilter
    })
    setExclusionMultipleValue(() => {
      const udpatedExclusionFilter = {
        onlyTrashed: exclusionMultipleValue?.onlyTrashed,
        advisorId: exclusionMultipleValue?.advisorId,
        searchParam: exclusionMultipleValue?.searchParam,
        ...exclusionFilter
      }
      return udpatedExclusionFilter
    })
  }

  useEffect(() => {
    if (autoScrollSpeedLock) return
    localStorage.setItem('autoScrollSpeed', String(autoScrollSpeed))
    window.distance = autoScrollSpeed
  }, [autoScrollSpeed])

  const addArrowScrollEvents = (e: KeyboardEvent) => {
    const elm = document.getElementById('student-data')

    if (e.ctrlKey === true) {
      if (e.code == 'BracketRight') {
        debounce(() => {
          setAutoScrollSpeed((prev) => {
            return prev < 300 ? prev + 10 : prev
          })
        }, 200)
        return
      }
      if (e.code == 'BracketLeft') {
        debounce(() => {
          setAutoScrollSpeed((prev) => {
            return prev > 10 ? prev - 10 : prev
          })
        }, 200)
        return
      }
    }

    if (e.key == 'ArrowRight' && elm) {
      elm.scrollLeft += Number(window?.distance)
    }
    if (e.key == 'ArrowLeft' && elm) {
      elm.scrollLeft -= Number(window?.distance)
    }
  }

  const selectDeselectAll = (action: boolean) => {
    action ? setSelectedStudent([...ids]) : setSelectedStudent([])
  }

  const handleCheckboxChange = (studentId: number) => {
    setSelectedStudent((prevState) => {
      if (prevState.includes(studentId)) {
        return prevState.filter((id) => id !== studentId)
      } else {
        return [...prevState, studentId]
      }
    })
  }

  const changeAdvisorInBulk = async (data: any) => {
    if (selectedStudentId.length == 0) {
      toast('Please select students', 'error')
      return false
    }
    setLoading(true)
    const payload = {
      studentId: selectedStudentId,
      advisor_id: data.advisor_assign
    }
    try {
      const advisor = await bulkAssignAdvisor(payload)
      if (advisor.success) {
        setConfirmModalOpen(false)
        setLoading(false)
        toast(advisor?.message, 'success')
        getStudents()
      } else {
        setLoading(false)
        toast('An error occurred', 'error')
      }
    } catch (error) {
      setLoading(false)
      toast('An error occurred', 'error')
    }
  }

  const shortByColumn = (columnId: any) => {
    if (sortOrder.columnId === columnId) {
      setSortOrder((prevState) => ({
        columnId,
        ascending: !prevState.ascending
      }))
    } else {
      setSortOrder({ columnId, ascending: true })
    }
  }

  const performAction = () => {
    if (selectedStudentId.length == 0) {
      toast('Please select students', 'error')
      return false
    }
    const payload = {
      studentId: selectedStudentId
    }
    http
      .fetch({ method: 'put', path: `students/${null}/${action}`, data: payload })
      .catch(() => {
        toast('Internal server error.', 'error')
        setConfirmAlertOpen(false)
      })
      .then((response) => {
        if (response && response.status === 200) {
          toast(response?.data.message, 'success')
          getStudents()
        }
        setConfirmAlertOpen(false)
        selectDeselectAll(false)
      })
  }

  const exportTo = (format: string) => {
    return getAllStudent({
      fields: column,
      filterData: multipleValue,
      exclusionFilterData: exclusionMultipleValue,
      sortOrder,
      params: { download: format }
    })
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      event.preventDefault()
      const imageFile = event.target.files ? event.target.files[0] : ''
      if (imageFile) {
        setStudentFile(imageFile)
      }
      await setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  async function uploadStudentCSV() {
    await setLoading(true)
    const fileRes = await uploadFile(studentFile, undefined)
    if (fileRes.success === true) {
      const documentId = fileRes.document.id

      http
        .fetch({ path: 'student/studentImport', method: 'post', data: { id: documentId } })
        .then((response) => {
          setLoading(false)
          if (response && response.status === 200) {
            toast(response?.data?.message || 'Successfully uploaded', 'success')
            setStudentFile('')
            setUploadInfoModal(false)
          }
        })
        .catch((error) => {
          toast(error, 'error')
          setLoading(false)
        })
    }
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  return (
    <div className='w-full lg:px-4 flex student-listing' data-cy='students-page'>
      {loading && <Loading />}
      {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_FILTERS) && (
        <Filter show={openFilter} setOPenFilter={setOPenFilter} submit={handleFilter} />
      )}
      <View show={openView} setOpenView={setOpenView} />
      <div
        className={`px-5 py-5 overflow-auto md:px-0 ml-auto transition-all duration-300 lg:px-0 ${openFilter ? 'w-[calc(100%-20%)] lg:w-[calc(100%-0%)]' : 'w-full'}`}
      >
        <div className='flex gap-4 justify-end'>
          <button
            className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
            data-cy='student-view-button'
            onClick={() => {
              setUploadInfoModal(true)
            }}
          >
            Bulk Upload Student Info
          </button>
          <button
            className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
            data-cy='student-view-button'
            onClick={() => {
              setOpenView(!openView)
            }}
          >
            Views
          </button>
        </div>
        <div className='flex justify-between items-center mt-5 lg:flex-wrap md:mt-0'>
          <div className='flex gap-4 items-center'>
            <h1 className='text-3xl font-bold lg:mb-5 capitalize'>
              <span className={`${multipleValue?.onlyTrashed && 'underline underline-offset-2 decoration-dotted'}`}>
                {multipleValue?.onlyTrashed ? multipleValue?.onlyTrashed?.replace('Application', '') : 'Admission'}
              </span>
              <span className='ml-2'>Students</span>
            </h1>
            <Tooltip
              title='Use Keyboard ArrowRight/ArrowLeft keys to scroll students listing horizontally. Use `Ctrl + ]` to increase or `Ctrl + [` to decrease scroll speed with arrow key.'
              classes={{ popper: '[&_div]:text-base' }}
              enterTouchDelay={1}
              placement='bottom'
              arrow
            >
              <span>
                <BsQuestionCircleFill className='size-5 md:size-7 mt-1 text-slate-400 cursor-pointer' />
              </span>
            </Tooltip>
            <div className='text-sm'>Auto scroll speed: {autoScrollSpeed}</div>
          </div>
          <div className='flex gap-4 lg:min-w-full lg:justify-end lg:flex-wrap md:gap-2 md:justify-end'>
            <div className='flex gap-4 items-center'>
              <FormGroup>
                <input
                  data-cy='search-input'
                  className='form-input'
                  id='grid-first-name'
                  type='text'
                  value={inputSearch}
                  placeholder='Search'
                  onChange={(e) => setInputSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Enter') return

                    setMultipleValue((prevState) => {
                      return {
                        ...prevState,
                        searchParam: inputSearch
                      }
                    })
                  }}
                />
              </FormGroup>
              <button
                data-cy='search-button'
                className='flex items-center gap-2 btn btn-primary px-4'
                onClick={() => {
                  setMultipleValue((prevState) => {
                    return {
                      ...prevState,
                      searchParam: inputSearch
                    }
                  })
                }}
              >
                <MdSearch size={'30px'} title='Search' />
              </button>
            </div>
            {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_FILTERS) && (
              <button
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => setOPenFilter(!openFilter)}
                data-cy='show-filters'
              >
                <MdOutlineFilterAlt size={'30px'} className='cursor-pointer' title='Filters' />
                Show Filters
              </button>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_FILTER_FIELDS) && (
              <button
                data-cy='change-filter-fields'
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => {
                  setOpenToggleFilter(!openToggleFilter)
                  setOpenColumnFilter(false)
                }}
              >
                <TbFilterEdit size={'30px'} className='cursor-pointer' title='Toggle Filters' />
                Change Filters Fields
              </button>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_DISPLAY_COLUMNS) && (
              <button
                data-cy='change-display-columns'
                className='flex items-center gap-2 btn btn-primary px-4 md:w-9/12'
                onClick={() => {
                  setOpenColumnFilter(!openColumnFilter)
                  setOpenToggleFilter(false)
                }}
              >
                <MdViewColumn size={'30px'} className='cursor-pointer' title='Toggle Fields' />
                Change Display Columns
              </button>
            )}
          </div>
        </div>
        <div className='flex justify-between items-center mt-5 lg:flex-wrap lg:flex-col-reverse lg:items-start'>
          <div className='flex gap-3'>
            <PerPage
              onChange={(value) => {
                setItemsPerPage(value)
                getStudents(1, value)
              }}
            />
            <FreezeTableColumns containerSelector='.student-listing' />
          </div>

          <div className='flex gap-x-4 items-center lg:min-w-full lg:justify-end lg:flex-wrap md:relative md:flex-col md:items-end md:pb-11 relative'>
            {isManagerAdvisor(data.roles) && userCan(data, PermissionEnum.STUDENT_LIST_YOUR_STUDENTS) && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => {
                        const value = !multipleValue.advisorId
                        handleFilter({ ...multipleValue, advisorId: value ? value : undefined })
                      }}
                      checked={Boolean(multipleValue?.advisorId || false)}
                      value={'advisorId'}
                    />
                  }
                  label='Your Students'
                />
              </FormGroup>
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_SHOW_ARCHIVED_STUDENTS) && (
              <div className='min-w-48 relative'>
                <Suspense fallback={<Loading className='!absolute !justify-start' data-loading='SuspenseLoader' />}>
                  {shouldRender ? (
                    <ViewByFilter multipleValue={multipleValue} handleFilter={handleFilter} />
                  ) : (
                    <Loading className='!absolute !justify-start' />
                  )}
                </Suspense>
              </div>
            )}
            {isAdmin(data.roles) && (
              <FormControl sx={{ m: 1, minWidth: 80 }} className='!min-w-44'>
                <InputLabel id='student-list-bulk-action-select-label'>Bulk Action</InputLabel>
                <Select
                  data-cy='bulk-action-select'
                  id='student-list-bulk-action-select'
                  labelId='student-list-bulk-action-select-label'
                  label='Bulk Action'
                  className=''
                  value={''}
                >
                  {multipleValue?.onlyTrashed === BulkActionsEnum.ArchiveApplication ? (
                    <MenuItem
                      value={BulkActionsEnum.UnArchiveApplication}
                      onClick={() => {
                        setConfirmAlertOpen(true)
                        setAction(BulkActionsEnum.UnArchiveApplication)
                        setActionLabel('Unarchive Application')
                      }}
                    >
                      UnArchive Application
                    </MenuItem>
                  ) : (
                    <div>
                      <MenuItem
                        value={BulkActionsEnum.AssignAdvisor}
                        onClick={() => {
                          setConfirmModalOpen(true)
                        }}
                      >
                        Assign Advisor
                      </MenuItem>
                      <MenuItem
                        value={BulkActionsEnum.MoveToApplication}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.MoveToApplication)
                          setActionLabel('Move To Application')
                        }}
                      >
                        Move To Application
                      </MenuItem>
                      <MenuItem
                        value={BulkActionsEnum.MoveToCurrent}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.MoveToCurrent)
                          setActionLabel('Move To Current')
                        }}
                      >
                        Move To Current
                      </MenuItem>
                      <MenuItem
                        value={BulkActionsEnum.MoveToAlumni}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.MoveToAlumni)
                          setActionLabel('Move To Alumni')
                        }}
                      >
                        Move To Alumni
                      </MenuItem>
                      <MenuItem
                        value={BulkActionsEnum.WithdrawApplication}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.WithdrawApplication)
                          setActionLabel('Withdraw Application')
                        }}
                      >
                        Withdraw Application
                      </MenuItem>
                      <MenuItem
                        value={BulkActionsEnum.ArchiveApplication}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.ArchiveApplication)
                          setActionLabel('Archive Application')
                        }}
                      >
                        Archive Application
                      </MenuItem>

                      <MenuItem
                        value={BulkActionsEnum.DeleteApplication}
                        onClick={() => {
                          setConfirmAlertOpen(true)
                          setAction(BulkActionsEnum.DeleteApplication)
                          setActionLabel('Delete Application')
                        }}
                      >
                        Delete Application
                      </MenuItem>
                    </div>
                  )}
                </Select>
              </FormControl>
            )}
            <Export callback={exportTo} />
            <button
              data-cy='refresh'
              className='btn-primary uppercase flex gap-3 items-center p-3 md:m-2'
              onClick={() => getStudents(currentPage)}
              title='Refresh students data'
            >
              <FaSync />
            </button>
          </div>
        </div>

        {state.fields && state.fields.length > 0 && (
          <>
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_FILTER_FIELDS) && (
              <ColumnFilter
                show={openToggleFilter}
                setOpenColumnFilter={setOpenToggleFilter}
                isToggleFilter={true}
                key={'toToggleFilters'}
                getFields={getFields}
              />
            )}
            {userCan(data, PermissionEnum.STUDENT_LIST_CHANGE_DISPLAY_COLUMNS) && (
              <ColumnFilter
                show={openColumnFilter}
                setOpenColumnFilter={setOpenColumnFilter}
                key={'toChangeColumns'}
                getFields={getFields}
              />
            )}
          </>
        )}

        <div className='overflow-auto custom-scrollbar' id='student-data'>
          <table className='w-full lg:w-max' data-cy='student-table'>
            <thead>
              <tr className='[&>*]:bg-primary [&>*]:text-white [&>*]:tracking-wider [&>*]:py-3 [&>*]:border-slate-500 [&>*]:text-center'>
                <th>#</th>
                <th className='cursor-pointer'>
                  <Checkbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: '#ffffff' } }}
                    checked={selectedStudentId.length === ids.length && selectedStudentId.every((value) => ids.includes(value))}
                    onChange={(e) => {
                      selectDeselectAll(Boolean(e.target.checked))
                    }}
                  />
                </th>
                {fields
                  ?.filter((field) => column.includes(field.id))
                  .sort((a, b) => a.filterColumnOrder - b.filterColumnOrder)
                  .map((item, index) => {
                    return (
                      <React.Fragment key={`action_column_table_header_${index}`}>
                        {item.id === 'action' || item.id === 'assign_advisor' ? (
                          <th key={-2}>{item.label}</th>
                        ) : (
                          <th
                            key={index}
                            className='cursor-pointer'
                            onClick={() => {
                              shortByColumn(item.id)
                            }}
                          >
                            <div className='flex gap-3 justify-between items-center'>
                              <span className='text-nowrap'>{item.label}</span>
                              <FaSort className='size-4' />
                            </div>
                          </th>
                        )}
                      </React.Fragment>
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {students.data.length === 0 && (
                <tr>
                  <td colSpan={1000}>No Students Found</td>
                </tr>
              )}
              {Object.values(students.data).map((student: any, studentIndex: number) => {
                return (
                  <tr key={studentIndex}>
                    <td className='text-center'>{(students?.currentPage - 1) * students?.perPage + studentIndex + 1}</td>
                    <td className='cursor-pointer'>
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                        onChange={() => handleCheckboxChange(student.id)}
                        checked={selectedStudentId.includes(student.id)}
                      />
                    </td>
                    {fields
                      ?.filter((field) => column.includes(field.id))
                      .sort(function (a, b) {
                        return a.filterColumnOrder - b.filterColumnOrder
                      })
                      .map((item, index) => {
                        return (
                          <React.Fragment key={`action_column_table_cell_${index}`}>
                            <td key={index} className='space-y-3'>
                              {item.id === 'advisor_id' ? (
                                student.advisor_id ? (
                                  <>
                                    {advisorList &&
                                      advisorList.map(
                                        (advisor, index) =>
                                          student.advisor_id == advisor.id && (
                                            <div key={index} className='flex '>
                                              <ImageSelector
                                                size='50px'
                                                isViewOnly={true}
                                                imageSrc={advisor.photoLink ?? noImagePlaceholder}
                                              />
                                              <label id='student-list-advisor-select-label text-nowrap' className='mx-2 self-center'>
                                                {advisor?.name}
                                              </label>
                                            </div>
                                          )
                                      )}
                                  </>
                                ) : (
                                  <label className='text-red-600 text-nowrap'>{'Advisor not  assigned'}</label>
                                )
                              ) : typeof student[item.id] === 'object' ? (
                                <div key={index}>{student[item.id]?.name || student[item.id]?.title || '-'}</div>
                              ) : String(student[item.id]).startsWith('[') && String(student[item.id]).endsWith(']') ? (
                                <div className='flex flex-wrap gap-3'>
                                  {JSON.parse(student[item.id]).map((val: any, index: any) => {
                                    return (
                                      <span
                                        className='block w-full px-3 py-1 bg-slate-100 border border-slate-200 rounded-full font-semibold text-sm text-black text-nowrap max-w-min'
                                        key={index}
                                      >
                                        {val}
                                      </span>
                                    )
                                  })}
                                </div>
                              ) : typeof student[item.id] === 'string' &&
                                (String(student[item.id]).includes(IMG_BASE_URL) || String(student[item.id]).includes(GF_SIGN_BASE_URL)) ? (
                                <FileUpload
                                  fileUrl={student[item.id]}
                                  isViewOnly={true}
                                  size={50}
                                  nameVisible={item.id !== 'profile_image'}
                                />
                              ) : item.id === 'application_status' ? (
                                <div
                                  className='badge badge-primary capitalize text-center w-4/5'
                                  style={AppStatusStyle[student?.application_status?.toLowerCase()]}
                                >
                                  {student[item.id]}
                                </div>
                              ) : Object.keys(student.sub_steps_status)?.includes(item.id) ? (
                                student.sub_steps_status[item.id] === 'completed' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.completeStepIcon}
                                    alt='Step Complete'
                                    title='Completed'
                                  />
                                ) : student.sub_steps_status[item.id] === 'recheck' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.recheckStepIcon}
                                    alt='Step Needs To Be ReChecked'
                                    title='Need to be rechecked'
                                  />
                                ) : student.sub_steps_status[item.id] === 'ready_to_review' ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.readyToReviewStepIcon}
                                    alt='Step Ready To Review'
                                    title='Ready to review'
                                  />
                                ) : (
                                  <span className='block text-center'>-</span>
                                )
                              ) : item.id === 'due_date' ? (
                                formatDate(student.billing_and_deposit?.due_date, '-')
                              ) : item.id === 'deposit_paid_date' ? (
                                formatDate(student.billing_and_deposit?.deposit_paid_date, '-')
                              ) : item.id === 'invoice_balance_due_date' ? (
                                formatDate(student.billing_and_deposit?.invoice_balance_due_date, '-')
                              ) : item.id === 'invoice_balance_paid_date' ? (
                                formatDate(student.billing_and_deposit?.invoice_balance_paid_date, '-')
                              ) : item.id === 'student_info_view' ? (
                                <Link
                                  data-cy='student-info'
                                  className='w-full btn btn-secondary flex justify-center items-center gap-x-3 whitespace-nowrap'
                                  to={`/admin/student-info/${student.id}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  Student Info <FaEye />
                                </Link>
                              ) : item.id === 'action' ? (
                                <Link
                                  data-cy='student-view'
                                  className='w-full btn btn-secondary flex justify-center items-center gap-x-3 whitespace-nowrap'
                                  to={`/admin/student-dashboard/${student.id}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  Admission View <FaEye />
                                </Link>
                              ) : item.id === 'assign_advisor' ? (
                                <div className='flex gap-5 items-center'>
                                  {isAdmin(data.roles) && (
                                    <button
                                      onClick={() => {
                                        if (!multipleValue.onlyTrashed) {
                                          setSelectedStudent([student.id])
                                          setSelectedAdvisor(student.advisor_id)
                                          setConfirmModalOpen(true)
                                        }
                                      }}
                                      className={`btn-primary flex items-center gap-x-2 text-nowrap ${multipleValue.onlyTrashed ? 'opacity-50 cursor-not-allowed' : ''}`}
                                      title={multipleValue.onlyTrashed ? 'Need to Un-Archive this student first.' : ''}
                                    >
                                      Assign Advisor
                                    </button>
                                  )}
                                </div>
                              ) : isValidDate(student[item.id]) && String(student[item.id]).length >= 8 ? (
                                formatDate(student[item.id], student[item.id], item?.id)
                              ) : item.id === 'tagged_in_internal_notes' ? (
                                Number(student[item.id]) > 0 ? (
                                  <img
                                    className='size-7 mx-auto'
                                    src={subStepStatusIcons.readyToReviewStepIcon}
                                    alt='You are tagged in internal notes.'
                                    title='You are tagged in internal notes.'
                                  />
                                ) : (
                                  '-'
                                )
                              ) : item.id === 'application_status_info' ? (
                                student['status_info']?.length > 0 ? (
                                  <div className='flex flex-wrap gap-3'>
                                    {student['status_info']?.map((val: { name: string }, index: number) => {
                                      return (
                                        <span
                                          className='block w-full px-3 py-1 bg-slate-100 border border-slate-200 rounded-full font-semibold text-sm text-black text-nowrap max-w-min'
                                          key={index}
                                        >
                                          {val.name}
                                        </span>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  '-'
                                )
                              ) : (
                                student[item.id]
                              )}
                            </td>
                          </React.Fragment>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {Boolean(students?.data?.length ?? 0 > 0) && (
          <div className='flex items-center justify-between mr-5 [&>ul]:flex [&>ul]:gap-3 [&>*_a]:py-2 [&>*_a]:px-4 [&>*_a]:lg:py-1 [&>*_a]:lg:px-3 [&>*_a]:inline-block mt-4 [&>*_a]:border [&>*_a:hover]:bg-primary [&>*_a:hover]:text-white [&>*_li.active>a]:bg-primary [&>*_li.active>a]:text-white'>
            <ReactJsPagination
              activePage={students?.currentPage || 1}
              itemsCountPerPage={students?.perPage}
              totalItemsCount={students?.total || 0}
              pageRangeDisplayed={students?.to}
              onChange={(pageNumber) => {
                getStudents(pageNumber)
              }}
              activeClass={`active ${students?.currentPage == 1 && students?.lastPage == 1 && 'pointer-events-none'}`}
            />
            <label className=' select-none'>{`Page ${students?.currentPage} of ${students?.lastPage} (Total ${students?.total} students)`}</label>
          </div>
        )}
      </div>
      <ConfirmationModal
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={(value: boolean) => {
          setConfirmModalOpen(value)
          if (!value) {
            setSelectedAdvisor(null)
          }
        }}
        deleteButtonText='Assign'
        advisors={advisorList}
        onAction={changeAdvisorInBulk}
        title={'Assign Advisor'}
        assignedAdvisor={selectedAdvisor}
      />
      <ConfirmationAlert
        title={`${action === 'deleteApplication' ? 'This action can not be reversed. \n' : ''} Are you sure you perform this action ?`}
        isConfirmModalOpen={isConfirmAlertOpen}
        setConfirmModalOpen={setConfirmAlertOpen}
        ButtonText={'Yes I am sure'}
        actionName={actionLabel}
        onAction={() => {
          performAction()
        }}
      />

      <Modal
        open={uploadInfoModal}
        className='flex justify-center items-center'
        disableAutoFocus={true}
        onClose={() => setUploadInfoModal(false)}
      >
        <div className='bg-white w-96 md:w-3/5 flex flex-col h-auto max-h-[90%]'>
          <div className='flex gap-3 justify-between items-center p-6'>
            <h1 className='text-xl font-semibold'>Upload Student Info</h1>
            <div onClick={() => setUploadInfoModal(false)} className='cursor-pointer'>
              <Close />
            </div>
          </div>
          <div className='my-4 overflow-y-auto px-6 w-full flex flex-col'>
            <div className='flex gap-5 items-center justify-between w-full'>
              <label className='whitespace-nowrap text-base tracking-wide'>Download Sample csv</label>
              <a
                href={'https://isiabroad-crm.s3.amazonaws.com/documents/1735796891-sample-student-info-bulk-upload-csv.csv'}
                download
                rel='noreferrer'
              >
                <Download />
              </a>
            </div>

            <div className='flex gap-5 items-start justify-between w-full mt-5'>
              <label className='whitespace-nowrap text-base tracking-wide'>Select csv file</label>
              <div className='flex flex-col items-end'>
                <div className='flex whitespace-nowrap'>
                  <Button component='label' variant='contained' tabIndex={-1} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <VisuallyHiddenInput type='file' multiple={false} accept='.csv' onChange={(event) => handleFileChange(event)} />
                  </Button>
                </div>
                <label className='text-end'>{studentFile ? studentFile?.name : ''}</label>
              </div>
            </div>

            <div className='mt-5 flex justify-center'>
              <button type='submit' className='btn btn-secondary w-min' disabled={loading} onClick={() => uploadStudentCSV()}>
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
